.cdc{
    &-sorteos{
        &-users{
            &-container{
                flex: 1 1 0px;
            }
            &-buttons{
                display: flex;
                justify-content: center;
                flex-direction: column;
                margin: 0px 4%;
                button{
                    &:first-child{
                        margin-bottom: 10px;
                    }
                }
            }
            &-check{
                width: 28px;
                cursor: pointer;
                margin: 0 5px 0 0;
                &-img{
                    vertical-align: middle;
                }
            }
            &-title{
                margin-bottom: 5px!important;
                font-weight: 600!important;
            }
            &-in{
                border-color: rgb(224, 224, 224);
                border-bottom: 1px solid rgb(224, 224, 224);
                border-top: 1px solid rgb(224, 224, 224);
                border-left: 1px solid rgb(224, 224, 224);
                border-right: 1px solid rgb(224, 224, 224);
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                padding: 8.5px 14px;
                width: 100%;
                max-height: 500px;
                min-height: 100px;
                overflow: auto;
                &-search{
                    font-family: "Roboto","Helvetica","Arial",sans-serif;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.4375em;
                    letter-spacing: 0.00938em;
                    border: 1px solid rgba(0, 0, 0, 0.38);
                    border-radius: 4px;
                    box-sizing: content-box;
                    width: 100%;
                    padding: 8.5px 14px;
                    margin-bottom: 20px;
                }
                .item{
                    //background-color: aliceblue;
                    padding: 2px;
                    cursor: pointer;
                    &:hover{
                        background-color: aliceblue;
                    }
                }
            }
        }
    }
    &-install{
        &-button{
            background-color: transparent;
            border: none;
            cursor: pointer;
        }
        &-dialog{
            padding: 0 !important; 
            background-color: #1c1d20;
            &-not{
                h2{
                    color: #fff;
                }
            }
            &-top{
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #5e6062;
                padding: 20px 24px 10px 24px;
                align-items: center;
                h2{
                    color: #fff;
                }
            }
            &-bottom{
                padding: 20px 24px;
            }
            &-sum{
                padding-bottom: 20px;
                border-bottom: 1px solid #5e6062;
                text-align: center;
                p{
                    color: #6b6d6f;
                    font-size: 14px;
                }
            }
            &-ul{
                list-style-type: none;
                padding: 0;
            }
            &-li{
                display: flex;
                align-items: center;
                figure{
                    margin: 5px 10px 0 0;
                }
                p{
                    color: #6b6d6f;
                    font-weight: 500;
                }
                a{
                    color: #1976d2;
                    &:hover{
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    &-red-flag{
        border-bottom: 1px solid red !important;
        outline: none;
        &-picker{
            div{
                border-bottom: 1px solid red !important;
                outline: none;
                border-radius: 0;
            }
        }
    }
    &-invite{
        &-code{
            strong{
                color: #304D30;
            }
        }
    }
    &-error-call{
        font-family: "Roboto","Helvetica","Arial",sans-serif;
        text-align: center;
        margin: 20px 0;
        font-weight: 600;
    }
    &-join{
        font-family: "Roboto","Helvetica","Arial",sans-serif;
        color: #fff;
        &-title{
            font-size: 20px;
            font-weight: 700;
            line-height: 27px;
        }
        &-card{
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #304D30;
            margin-top: 52px;
            border-radius: 60px;
            img{
                margin: 10px;
            }
            p{
                color: #fff;
                padding: 0 50px;
                margin-top: 0;
            }
            button{
                margin-bottom: 32px;
                font-size: 28px;
                text-align: center;
            }
        }
    }
    &-joinclub{
        &-next,&-prev{
            border-radius: 10px;
            padding: 20px 10px;
            width: 40%;
            font-size: 16px;
            font-family: "Roboto","Helvetica","Arial",sans-serif;
            font-weight: 600;
            cursor: pointer;
            margin-bottom: 30px !important;
        }
        &-next{
            background-color: #304D30;
            border: 1px solid #304D30;
            color: #fff;
        }
        &-prev{
            background-color: #fff;
            border: 1px solid #304D30;
            color: #304D30;
        }
    }
    &-contact, &-joinclub{
        &-title{
            font-family: "Roboto","Helvetica","Arial",sans-serif;
            font-size: 20px;
            font-weight: 700;
            line-height: 27px;
            max-width: 330px;
            margin: 25px auto 0 auto;
            &-container{
                margin-top: 45px;
                margin-bottom: 25px;
            }
        }
        &-figure{
            width: 180px;
            margin: 25px auto;
            a{
                img{
                    display: block;
                    margin: 0 auto;
                }
            }
        }
        &-input-inline{
            flex-direction: row !important;
            flex: 1 1 0px;
            div{
                flex: 1 1 0px;
                &:first-child{
                    margin-right: 3%;
                }
                &:last-child{
                    margin-left: 3%;
                }
            }
        }
        &-input,&-input-inline{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 22px;
            width: 100%;
            label{
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                font-weight: 500;
                font-size: 10px;
                line-height: 11px;
                color: #828282;
                margin-bottom: 5px;
            }
            textarea{
                max-width: 100%;
                min-width: 100%;
                min-height: 40px;
            }
            input, textarea, select{
                width: 100%;
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                box-sizing: border-box;
                padding: 10px 10px 10px 0;
                border-top: 0;
                border-left: 0;
                border-right: 0;
                border-bottom: 1px solid #f2f2f2;
                outline: none;
                font-size: 16px;
                &:focus{
                    border-bottom: 1px solid #000;
                }
                &::placeholder{
                    font-size: 16px;
                    color: #bdbdbd;
                    font-family: "Roboto","Helvetica","Arial",sans-serif;
                }
            }
        }
        &-button{
            background-color: #304D30;
            border-radius: 10px;
            padding: 20px 10px;
            width: 100%;
            border: 0;
            color: #fff;
            font-size: 16px;
            font-family: "Roboto","Helvetica","Arial",sans-serif;
            font-weight: 600;
            cursor: pointer;
            margin-bottom: 30px;
        }
        &-errors{
            display: flex;
            flex-direction: column;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            margin-bottom: 30px;
            border-left: 2px solid red;
            padding-left: 12px;
        }
        &-filter{
            margin-top: 15px;
        }
        &-category{
            background-color: #304D30;
            border-radius: 10px;
            padding: 10px 10px;
            border: 1px solid #304D30;
            color: #fff;
            font-size: 15px;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 400;
            cursor: pointer;
            margin: 10px 5px 8px 5px;
            width: 30%;
            &:nth-child(3n+1){
                margin-left: 0
            }
            &:nth-child(3n+3){
                margin-right: 0;
            }
            &-active{
                background-color: #fff;
                color: #304D30;
            }
        }
    }
    &-raffles{
        &-heart, &-fav{
            background-image: url(../../assets/mobile/lateralmenu/heart.svg);
            background-repeat: no-repeat;
        }
        &-heart{
            background-position: left;
            padding-left: 35px;
        }
        &-fav{
            background-position: right 10px top 0px;
            line-height: 22px;
        }
        &-exclusive{
            &-basic{
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                font-size: 14px;
                font-weight: 700;
                color: #B00020;
            }
            &-subscribe{
                margin: 15px 0;
                button{
                    font-size: 16px;
                    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                    font-weight: 600;
                    width: 100%;
                    height: 56px;
                    border-radius: 10px;
                    background-color: #304D30;
                    border: 2px solid #304D30;
                    color: #fff;
                    cursor: pointer;
                }
            }
            &-subscribed{
                margin: 15px 0;
                button{
                    font-size: 16px;
                    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                    font-weight: 600;
                    width: 100%;
                    height: 56px;
                    border-radius: 10px;
                    background-color: #E0E0E0;
                    border: 2px solid #E0E0E0;
                    color: #fff;
                }
            }
            &-info{
                display: flex;
                justify-content: space-around;
                .item-one, .item-two{
                    text-align: center;
                    padding: 10px 20px;
                    border-radius: 10px;
                    width: 120px;
                    height: 120px;
                    .number{
                        font-size: 36px;
                        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                        font-weight: 700;
                        margin: 10px 0;
                    }
                    .text{
                        font-size: 20px;
                        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                        font-weight: 400;
                        margin: 10px 0;
                    }
                }
                .item-one{
                    border: 3px solid #e0e0e0;
                }
                .item-two{
                    border: 3px solid #304D30;
                }
            }
        }
        &-modal{
            &-content{
                text-align: center;
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            }
            &-close{
                button{
                    font-size: 16px;
                    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                    font-weight: 600;
                    width: 100%;
                    height: 56px;
                    border-radius: 10px;
                    background-color: #304D30;
                    border: 2px solid #304D30;
                    color: #fff;
                    cursor: pointer;
                }
            }
        }
    }
    &-profile{
        &-banner{
            display: flex;
            box-shadow: 0px 0rem 0.8rem rgb(232 84 0 / 50%);
            border: 1px solid #E85400;
            border-radius: 12px;
            img{
                margin: 16px;
                //height: 87px;
                width: 100px;
                //border-radius: 100%;
            }
            h2{
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                font-weight: 400;
                font-size: 18px;
                line-height: 21px;
                letter-spacing: 0.17px;
            }
            h1{
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                font-weight: 700;
                font-size: 18px;
                line-height: 21px;
                letter-spacing: 0.17px;
            }
            &-texto{
                left: 20%;
                margin-left: 16px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                &-nSocio{
                    display:flex;
                    flex-direction: row;
                }
                &-apellido{
                    margin-top: 24px;
                }
                &-nombre{
                    margin-top: -12px;
                }
                &-numero{
                    margin-top: -4px;
                    margin-bottom: 24px;
                    margin-right: 5px;
                }
                &-socio{
                    margin-top: -4px;
                    margin-bottom: 24px;
                    color: #304D30;
                }
            }
            &-premium{
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 24px;
                gap: 8px;
                background: #FFFFFF;
                //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
                box-shadow: 0px 0rem 0.8rem rgb(232 84 0 / 50%);
                border: 1px solid #E85400;
                border-radius: 10px;
                margin: 0 20px;
                h1{
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 25px;
                    letter-spacing: 0.17px;
                    color: #000000;
                    margin: 0;
                }

                h4{
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    letter-spacing: 0.17px;
                    color: #304D30;
                    margin: 0 0 10px 0;
                }

            }
            &-socio{
                &-premium{
                    display: flex;
                    justify-content: space-evenly;
                    margin-top: 16px;
                }
            }
            
        }
        &-puntos{
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                font-size: 15px;
                line-height: 21px;
                letter-spacing: 0.17px;
        }
        &-form{
            &-buttons{
                display: flex;
                justify-content: space-between;
                margin-top: 39px;
                margin-bottom: 40px;
            }
            &-button{
                &-volver{
                    padding: 15px 45px;
                    background-color: #304D30 !important;
                    color: #FFFFFF !important;
                    border: 2px solid #304D30 !important;
                    border-radius: 10px !important;
                }
                &-guardar{
                    padding: 0 8px;
                    background-color: #304D30 !important;
                    color: #FFFFFF !important;
                    border: 2px solid #304D30 !important;
                    border-radius: 10px !important;
                    &[disabled]{
                        color: rgba(0, 0, 0, 0.26) !important;
                        box-shadow: none;
                        background-color: rgba(0, 0, 0, 0.12) !important;
                        border: 0 !important;
                        pointer-events: none;
                        cursor: default;
                    }
                }
            }
        }
        &-table{
            margin: 32px auto 0px 0px;
            p{
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                letter-spacing: 0.181818px;
                color: #828282;
                margin: 0;
            }
            h2{
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.2px;
                margin-top: 11px;
                padding-bottom: 13px;
                margin-bottom: 12px;
            }
            input{
                outline: none !important;
                border-top-style: hidden;
                border-right-style: hidden;
                border-left-style: hidden;
                border-bottom-style: hidden;
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.2px;
                margin-top: 11px;
                padding: 0 0 13px 0;
                border-bottom: 2px solid #F2F2F2;
                margin-bottom: 12px;
                width: -webkit-fill-available;
            }
            span{
                color: red;
                display: inline-block;
                margin-bottom: 10px;
            }
            &-passwd{
                display: flex;
                flex-direction: row;
                border-bottom: 1px solid #F2F2F2;
                justify-content: space-between;
            }
            &-select{
                width: 100%;
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                box-sizing: border-box;
                padding: 10px 10px 10px 0;
                border-top: 0;
                border-left: 0;
                border-right: 0;
                border-bottom: 1px solid #f2f2f2;
                outline: none;
                font-size: 16px;
            }
        }
        &-editar{
            display: flex;
            flex-direction: row;
            p{
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 0;
                margin-top: 0;
            }
            &-txt{
                color: #828282;
            }
            &-clickable{
                margin-left: 8px;
                color: #304D30;
                cursor: pointer;
            }
        }
        &-invitar{
            display: flex;
            a{
                line-height: 48px !important;
                cursor: pointer;
                text-decoration: none;
            }
            p, a{
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                margin-top: 10px;
            }
            &-txt{
                color: #828282;
            }
            &-clickable{
                margin-left: 8px;
                color: #304D30;
            }
        }
    }
    &-insurance{
        &-btn{
            background-color: #304D30 !important;
            height: 56px;
            width: 336px;
            color: #fff !important;
            border-radius: 10px!important;
        }
        justify-content: center;
        display: flex;
        margin-top: 32px;
    }
    &-license{
        display: block;
        figure{
            min-width: 100%;
            margin-top: 0;
            margin-block-end: 0;
            margin-inline-start: 0;
            margin-inline-end: 0;
            margin-left: 0;
            img{
                width: 100%;
            }
        }
        &-userT{
            h1{
                font-size: 20px;
                font-weight: 600;
                line-height: 25px;
            }
            p{
                font-size: 10px;
                font-weight: 500;
                line-height: 11.72px;
                color: #828282;
            }
            h2{
                font-size: 16px;
                font-weight: 400;
                line-height: 18.75px;
                color: #828282;
                padding-bottom: 13px;
                border-bottom: 1px solid #F2F2F2;
            }
            h3{
                font-size: 16px;
                font-weight: 600;
                line-height: 21px;
            }
            &-checkform{
                &-cols{
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                }
                display: flex;
                justify-content: center;
                flex-direction: column;
                p{
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 12px;
                    color: #303639;
                }
                input{
                    margin-bottom: 36px;
                }
                &-gridCol1{
                    grid-column: 1/2;
                }
                &-gridCol2{
                    grid-column: 2/3;
                }
                button{
                    background-color: #304D30;
                    height: 56px;
                    width: 336px;
                    color: #fff;
                    border-radius: 10px;
                    margin-bottom: 42px;
                    margin-left: -50px;
                    left: 32%;
                    &:hover{
                        background-color: #304D30;
                    }
                }
            }
        }
    }
    &-register{
        & > div{
            margin-bottom: 0
        }
    }
    &-home, &-blog-types, &-managements{
        a{
            display: grid;
            outline: none;
            text-decoration: none;
            min-width: 100%;
            margin-bottom: 24px;
            h1{
                grid-row: 2 / 3;
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                font-weight: 600;
                font-size: 16px !important;
                line-height: 19px;
                color: black;
            }
            p{
                grid-row: 3 / 4;
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: black;
                margin-top: -2px;
                margin-bottom: 0;
            }
            figure{
                grid-row: 1 / 2;
                display: flex;
                margin-top: 0;
                margin-block-end: 0;
                margin-inline-start: 0;
                margin-inline-end: 0;
                min-width: 100%;
                img{
                    width: 100%;
                    max-height: 168px;
                    border-radius: 8px;
                }
            }
        }
    }
    &-home{
        a{
            text-align: center;
        }
    }
    &-header{
        &-lateral{
            width: 55%;
            &-title{
                text-align: center;
                color: #304D30;
                font-weight: 700 !important;
                margin: 10px !important;
                //border-bottom: 1px solid;
            }
            &-links{
                padding: 0;
                button{
                    margin: 30px 0 0 15px;
                }
                .container-link{
                    height: 40px;
                    display: flex;
                    margin: 18px 15px 18px 15px;
                    align-items: center;
                    padding-left: 15px;
                    .profilecircle, .bookicon, .bankicon, .hearticon, .messageicon, .friendicon, .closeicon, .homeicon, .sorteoicon, .noticiasicon, .notificacionicon, .serviciosicon, .managementicon{
                        background-repeat: no-repeat;
                        height: 100%;
                        background-position: left;
                        padding-left: 35px;
                        display: flex;
                        align-items: center;
                    }
                    &-legal{
                        display: flex;
                        align-items: center;
                        padding-left: 15px;
                        margin-left: 15px;
                            a{
                                color: #5e6062;
                                font-family: "Roboto","Helvetica","Arial",sans-serif;
                                font-weight: 600;
                                font-size: 13px;
                                text-decoration: none;
                                width: 100%;
                                line-height: 30px;
                                cursor:pointer;
                            }
                            &:hover{
                                color: #303639;
                                border-radius: 0.25rem;
                            }  
                    }
                    .profilecircle{
                        background-image: url(../../assets/mobile/lateralmenu/profilecircle.svg);
                        &:hover{
                            background-image: url(../../assets/mobile/lateralmenu/profilecircle_hover.svg);
                        }
                    }
                    .bookicon{
                        background-image: url(../../assets/mobile/lateralmenu/book.svg);
                        &:hover{
                            background-image: url(../../assets/mobile/lateralmenu/book_hover.svg);
                        }
                    }
                    .bankicon{
                        background-image: url(../../assets/mobile/lateralmenu/bank.svg);
                        &:hover{
                            background-image: url(../../assets/mobile/lateralmenu/bank_hover.svg);
                        }
                    }
                    .hearticon{
                        background-image: url(../../assets/mobile/lateralmenu/heart.svg);
                        &:hover{
                            background-image: url(../../assets/mobile/lateralmenu/heart_hover.svg);
                        }
                    }
                    .messageicon{
                        background-image: url(../../assets/mobile/lateralmenu/message.svg);
                        &:hover{
                            background-image: url(../../assets/mobile/lateralmenu/message_hover.svg);
                        }
                    }
                    .homeicon{
                        background-image: url(../../assets/mobile/lateralmenu/home.svg);
                        &:hover{
                            background-image: url(../../assets/mobile/lateralmenu/home_hover.svg);
                        }
                    }
                    .noticiasicon{
                        background-image: url(../../assets/mobile/lateralmenu/noticias.svg);
                        &:hover{
                            background-image: url(../../assets/mobile/lateralmenu/noticias_hover.svg);
                        }
                    }
                    .notificacionicon{
                        background-image: url(../../assets/mobile/lateralmenu/notificacion.svg);
                        &:hover{
                            background-image: url(../../assets/mobile/lateralmenu/notificacion_hover.svg);
                        }
                    }
                    .serviciosicon{
                        background-image: url(../../assets/mobile/lateralmenu/servicios.svg);
                        &:hover{
                            background-image: url(../../assets/mobile/lateralmenu/servicios_hover.svg);
                        }
                    }
                    .sorteoicon{
                        background-image: url(../../assets/mobile/lateralmenu/sorteo.svg);
                        &:hover{
                            background-image: url(../../assets/mobile/lateralmenu/sorteo_hover.svg);
                        }
                    }
                    .friendicon{
                        background-image: url(../../assets/mobile/lateralmenu/anadiramigo.svg);
                        &:hover{ 
                            background-image: url(../../assets/mobile/lateralmenu/anadiramigo_hover.svg);
                        }
                    }
                    .closeicon{
                        background-image: url(../../assets/mobile/lateralmenu/cerrar.svg);
                        &:hover{
                            background-image: url(../../assets/mobile/lateralmenu/cerrar_hover.svg);
                        }
                    }
                    .managementicon{
                        background-image: url(../../assets/mobile/lateralmenu/management.svg);
                        &:hover{
                            background-image: url(../../assets/mobile/lateralmenu/management_hover.svg);
                        }
                    }
                    figure{
                        margin: 0 10px 0 0;
                    }
                    a{
                        color: #304D30;
                        font-family: "Roboto","Helvetica","Arial",sans-serif;
                        font-weight: 600;
                        font-size: 16px;
                        text-decoration: none;
                        width: 100%;
                        line-height: 22px;
                        &:hover{
                            color: #FFFFFF;
                        }
                    }
                    &:hover{
                        color: #FFFFFF;
                        background-color: #304D30;
                        border-radius: 0.25rem;
                    }
                }
            }
            .MuiDrawer-paper{
                width: 70%;
                background-color: #fff;
            }
        }
    }
    &-blog, &-raffles, &-notifications, &-gestiones, &-mensajes, &-offers{
        p{
            margin: 0;
            font-weight: 700;
        }
        h1{
            margin-left: 15px;
        }
        &-previous{
            font-family: "Roboto","Helvetica","Arial",sans-serif;
                font-weight: 600;
                font-size: 20px;
                line-height: 25px;
                letter-spacing: 0.17px;
        }
        &-container{
            margin: 16px auto;
            display: flex;
            height: 90px;
            list-style: none;
            overflow: hidden;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
            border-radius: 10px;
            background-color: #fff;
            &-first{
                list-style: none;
                figure{
                    margin: 0 0 8px 0;
                    img{
                        height: 200px;
                        width: 100%;
                        border-radius: 10px;
                    }
                }
            }
            &:hover{
                background-color: rgb(217,155,114,0.1);
                cursor: pointer;
                border-radius: 20px;
            }
            h2{
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            figure{
                margin: 0;
                img{
                    width: 90px;
                    height: 90px;
                    border-radius: 15px 0 0 15px;
                }
            }
            &-right{
                padding: 8px;
                width: 100%;
                position: relative;
            }
        }
        &-date, &-title, &-summary{
            font-family: "Roboto","Helvetica","Arial",sans-serif;
            font-size: 14px;
        }
        &-date{
            margin-bottom: 8px;
            font-weight: 400;
            color: #304D30;
        }
        &-title{
            margin-bottom: 5px;
            font-weight: bold;
            color: #000;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1 !important;
            -webkit-box-orient: vertical;
            &.first{
                margin-bottom: 8px;
            }
        }
        &-summary{
            color: #000;
            font-weight: 400 !important;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            &.first{
                -webkit-line-clamp: 3;
            }
        }
        &-search{
            padding: 16px 10px;
            border-radius: 10px;
            box-shadow: 0px 0px 15px rgb(107 127 153 / 30%);
            & > div {
                position: relative;
                display: flex;
                height: 40px;
                border-radius: 10px;
                background-color: #f2f2f2;
                input[type=submit]{
                    background-image: url(../../assets/mobile/lupapwa.svg);
                    background-repeat: no-repeat;
                    height: 100%;
                    background-position: center;
                    padding-left: 50px;
                    cursor: pointer;
                    border: 0;
                    border-radius: 10px;
                }
                input[type=text]{
                    width: 100%;
                    padding-right: 20px;
                }
            }
            input{
                border: 0;
                background-color: #F2F2F2;
                border-radius: 40px;
                font-size: 15px;
                outline: none;
            }
            p{
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                margin-bottom: 10px;
                font-size: 12px;
                font-weight: 500;
                color: #828282;
            }
        }
        &-list{
            padding: 0;
            &-link{
                text-decoration: none !important;
            }
        }
        &-pagination{
            ul{
                justify-content: center;
                li{
                    &:first-child{
                        border: 1px solid #304D30;
                    }
                        border-top: 1px solid #304D30;
                        border-right: 1px solid #304D30;
                        border-bottom: 1px solid #304D30;
                    button{
                        margin: 0;
                        border-radius: 0;
                        height: 56px;
                        width: 56px;
                        &[aria-current=true]{
                            background-color:#304D30;
                            color: #fff;
                        }
                        font-family: "Roboto","Helvetica","Arial",sans-serif;
                        color: #304D30;
                        font-size: 20px;
                    }
                }
            }
        }
        &-details{
            &-link{
                background-color: #304D30;
                border-radius: 10px;
                padding: 10px 10px;
                border: 1px solid #304D30;
                color: #fff !important;
                font-size: 16px;
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                font-weight: 600;
                cursor: pointer;
                margin: 10px 5px 32px 5px !important;
                display: block;
                text-align: center;
                text-decoration: none !important;
            }
            &-img{
                margin: 0;
                img{
                    height: 200px;
                    width: 100%;
                }
            }
            &-title{
                margin: 8px 0 !important;
                font-family: "Roboto","Helvetica","Arial",sans-serif !important;
                font-size: 20px !important;
                font-weight: 600 !important;
            }
            &-content{
                p{
                    font-family: "Roboto","Helvetica","Arial",sans-serif;
                    font-size: 14px;
                    line-height: 19px;
                    font-weight: 400;   
                }
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                font-size: 14px;
                line-height: 19px;
                font-weight: 400;
            }
        }
        &-notfound{
            text-align: center;
            margin-top: 30px;
            font-size: 16px;
            font-weight: 600;
        }
    }
    &-notifications, &-gestiones, &-mensajes{
        &-modal{
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            padding: 10px 15px;
            min-width: 240px;
            overflow: hidden;
            &-title{
                margin-bottom: 5px;
                font-weight: bold;
                color: #000;
                font-size: 14px;
            }
            &-summary{
                color: #000;
                font-weight: 400 !important;
                font-size: 14px;
            }
            &-close{
                padding: 0 !important;
                margin-top: 30px;
                button{
                    background-color: #304D30;
                    border-radius: 10px;
                    padding: 10px;
                    width: 100%;
                    border: 0;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 600;
                    cursor: pointer;
                }
            }
            &-separator{
                background-color: #E0E0E0;
                height: 1px;
                border: 0;
            }
            &-top{
                display: flex;
                justify-content: space-between;
                box-sizing: border-box;
                padding: 0;
            }
        }
        &-hhmm{
            color: #828282;
            font-weight: 500 !important;
            margin-right: 10px !important;
        }
        &-options{
            display: flex;
            align-items: center;
            margin-top: 15px;
            justify-content: space-between;
            p{
                font-weight: 500 !important;
                font-family: "roboto";
                font-size: 18px;
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                color:#828282;
            }
            &.status{
                border-radius: 8px;
                padding: 0 20px;
            }
            &.Close{
                background-color: rgba(0, 0, 0, 0.5);
            }
            &.New{
                background-color: rgba(0, 166, 50, 0.8);
            }
            &.Pending{
                background-color: rgba(220, 197, 0, 0.8);
            }
            &-sort{
                display:flex;
                align-items: center;
                background-color: transparent;
                border: 0;
                cursor: pointer;
                &:first-child{
                    margin-right: 20px;
                }
                figure{
                    margin: 0;
                }
                p{
                    font-weight: 500 !important;
                    font-family: "roboto";
                    font-size: 14px;
                    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                    color:#828282;
                }
            }
        }
        &-got{
            position: absolute;
            width: 8px;
            height: 8px;
            background-color: red;
            border-radius: 5px;
            top: 15px;
            right: 13px;
            box-shadow: 0px 0px 3px 3px red;
        }
        &-container{
            height: 83px;
            &-right{
                display: flex;
                justify-content: space-between;
            }
        }
        &-read{
            //animation: bounceNotification 1s infinite ease-in-out alternate;
            backface-visibility: hidden;
            -webkit-transform: translateZ(0) scale(1.0, 1.0);
            transform: translateZ(0);
            -webkit-font-smoothing: antialiased;
            background-color: lemonchiffon;
        }
        &-extend{
            position: absolute;
            top: 8px;
            right: 8px;
            &:before, &:after{
                content: "";
                width: 2px;
                height: 10px;
                display: block;
                background-color: #000;
                position: absolute;
                top: 8px;
            }
            &:after{
                transform: rotate(45deg);
                right: 10px;
            }
            &:before{
                transform: rotate(-45deg);
                right: 16px;
            }
        }
    }
    &-rrss{
        display: flex !important;
        justify-content: center;
        margin-top: 32px;
        margin-bottom: 15px;
        flex-wrap: wrap;
        a{
            margin: 10px 30px;
            figure{
                margin: 0;
            }
        }
    }
    &-documents{
        &-expire{
            color: red !important;
        }
        &-section{
            margin-top: 35px;
            height: 2px;
            background-color: rgba(48, 77, 48, 0.5);
            border: 0;
        }
        &-image{
            margin: 0;
            img{
                width: 100%;
            }
        }
        &-iframe{
            &-pdf{
                height: calc(100vh - 140px);
            }
        }
        &-modal{
            text-align: center;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            &-add{
                background-color: transparent;
                border: none;
                display: flex;
                align-items: center;
                cursor: pointer;
                p{
                    margin-left: 5px;
                    font-size: 14px;
                    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                    font-weight: 400;
                }
            }
            &-close{
                justify-content: center !important;
                button{
                    font-size: 16px;
                    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                    font-weight: 600;
                    width: 100%;
                    height: 56px;
                    border-radius: 10px;
                    background-color: #304D30;
                    border: 2px solid #304D30;
                    color: #fff;
                    cursor: pointer;
                    &[disabled]{
                        background-color: lightgrey;
                        border-color: lightgray;
                    }
                }
            }
            &-cookies{
                h3{
                    font-size: 26px;
                    font-weight: 700;
                }
                p,label,button{
                    font-size: 14px;
                }
            }
        }
        &-upload{
            &-caduc, &-exp{
                margin-bottom: 20px;
                .MuiFormControl-root{
                    width: 100%;
                }
            }
            &-type{
                margin-bottom: 20px;
                text-align: left;
                label{
                    font-family: "Roboto","Helvetica","Arial",sans-serif;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 11px;
                    color: #828282;
                    margin-bottom: 5px;
                }
                input,select{
                    width: 100%;
                    font-family: "Roboto","Helvetica","Arial",sans-serif;
                    box-sizing: border-box;
                    padding: 10px 10px 10px 0;
                    border-top: 0;
                    border-left: 0;
                    border-right: 0;
                    border-bottom: 1px solid #f2f2f2;
                    outline: none;
                    font-size: 16px;
                    &:focus{
                        border-bottom: 1px solid #000;
                    }
                    &::placeholder{
                        font-size: 16px;
                        color: #bdbdbd;
                        font-family: "Roboto","Helvetica","Arial",sans-serif;
                    }
                }
            }
            &-button{
                display: flex;
                width: 100%;
                flex-direction: column;
                align-items: center;
                position: relative;
                background-color: rgba(217, 155, 114, 0.2);
                border-radius: 20px;
                padding: 10px 20px;
                outline: none;
                border: 0;
                cursor: pointer;
                p{
                    margin-top: 8px;
                    margin-bottom: 4px;
                    font-weight: 600 !important;
                    font-size: 14px !important;
                }
            }
        }
        &-list{
            padding: 0;
            list-style: none;
            &-link{
                text-decoration: none;
            }
        }
        &-date{
            margin: 0 0 0 0 !important;
            font-weight: 700;
            color: #304D30;
        }
        &-title{
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 5px;
            font-weight: bold;
            color: #000;
            -webkit-line-clamp: 1 !important;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-size: 14px;
        }
        &-summary{
            color: #000;
            font-weight: 400 !important;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-size: 14px;
            margin: 0;
        }
        &-container{
            margin: 16px auto;
            display: flex;
            height: 100px;
            list-style: none;
            overflow: hidden;
            box-shadow: 0px 4px 4px rgb(0 0 0 / 8%);
            border-radius: 10px;
            background-color: #fff;
            &:hover{
                //background-color:rgba(217, 155, 114, 0.1);
                background-color:#9e9e9e1a;
                cursor: pointer;
                border-radius: 20px;
            }
            &-right{
                padding: 14px 15px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                &-center{
                    align-self: center;
                }
            }
            &-status{
                color: #000;
                font-weight: 600;
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                font-size: 14px;
                flex-basis: 25%;
                text-align: right;
            }
        }
        &-notfound{
            margin-top: 14px;
            font-family: "Roboto","Helvetica","Arial",sans-serif;
            font-weight: 600;
            line-height: 19px;
            letter-spacing: 0.17px;
            font-size: 14px;
        }
        &-button{
            background-color: #304D30;
            border-radius: 20px;
            padding: 26px 10px;
            width: 100%;
            border: 0;
            color: #fff;
            font-size: 16px;
            font-family: "Roboto","Helvetica","Arial",sans-serif;
            font-weight: 600;
            cursor: pointer;
            margin-bottom: 30px;
        }
        &-errors{
            margin-bottom: 0;
            margin-top: 8px;
            font-family: "Roboto","Helvetica","Arial",sans-serif;
            font-size: 14px;
            color: red;
            font-weight: 600;
        }
        &-file{
            &-container{
                margin-bottom: 20px;
            }
            &-delete{
                background-color: transparent;
                border: none;
                cursor: pointer;
            }
        }
        &-card{
            margin-bottom: 30px;
            position: relative;
            img{
                width: 100%;
            }
            p{
                font-size: 24px;
                font-weight: 400;
                text-align: right;
                line-height: 24px;
            }
            &-dni{
                position: absolute;
                top: 7%;
                right: 15%;
            }
            &-nombre{
                position: absolute;
                top: 44%;
                right: 15%;
                padding-left: 5%;
            }
            &-apellidos{
                position: absolute;
                font-weight: 600 !important;
                top: 54%;
                right: 15%;
                padding-left: 5%;
            }
            &-validez{
                position: absolute;
                top: 85%;
                left: 6%;
                font-size: 14px !important;
            }
            &-nSocio{
                position: absolute;
                top: 85%;
                right: 15%;
                font-size: 18px !important;
            }
        }
        &-dnit{
            h1{
                font-size: 20px;
                font-weight: 600;
                line-height: 25px;
                letter-spacing: 0.17px;
                margin-bottom: 10px;
            }
            p{
                font-size: 14px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0.17px;
            }
        }
        &-dni{
            display: flex;
            align-items: center;
            margin-top: 18px;
            margin-bottom: 18px;
            figure{
                margin-block-start: 1px;
                margin-block-end: 0;
                margin-inline-start: 0;
                margin-inline-end: 10px;
            }
            p{
                margin-right: 11px;
                font-size: 14px;
                font-weight: 600;
                line-height: 19px;
                letter-spacing: 0.17px;
                color: #EB5757;
            }
            button{
                border: none;
                cursor: pointer;
                appearance: none;
                background-color: inherit;
            }
            &-preview{
                &-img{
                    margin: 0;
                    img{
                        width: 100%;
                        height: 200px;
                    }
                }
            }
        }
        &-subir{
            display: flex;
            width: 100%;
            cursor: pointer;
            height: 50px;
            border-radius: 10px;
            justify-content: center;
            align-items: center;
            background-color: inherit;
            border-style: dashed;
            border-color: #304D30;
            position: relative;
               
            img{
                margin-block-start: -2px;
            }
            &-label{
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                color: #304D30;
                text-align: center;
                position: absolute;
                display: flex;
                align-items: center;
                margin-left: 0;
                cursor: pointer;
                img{
                    margin-right: 10px;
                }
            }
            &-inp{
                opacity: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;
            }
            &-inp::-webkit-file-upload-button{
                display: none;
            }
            &-inp::before{
                content: 'Subir fotografía DNI';
                display: inline-block;
                outline: none;
                cursor: pointer;
                -webkit-user-select: none;
            }
            &-inp .input-file{
                display: none;
            }
        }
        &-licencias{
            margin-top: 32px;
            &:last-child{
                margin-bottom: 32px;
            }
            h1{
                font-size: 20px;
                font-weight: 600;
                line-height: 25px;
                letter-spacing: 0.17px;
            }
            p{
                margin-top: 8px;
                font-size: 14px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0.17px;
            }
        }
    }
    &-gestion{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-top: 16px;
        margin-bottom: 36px;
        overflow: hidden;
        box-shadow: 4px 4px 4px #00000026;
        border-radius: 10px;
        padding: 8px;
        border: 1px solid #00000026;
        p{
            margin-top: 10px;
            margin-bottom: 10px;
        }
        h4{
            margin:0;
        }
        &-propertys{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        &-property{
            display: flex;
            flex-direction: column;
            &-time{
                display: flex;
                flex-direction: row;
            }
        }
    }

    &-mensaje{
        display: flex;
        
        flex-direction: column;
        margin-top: 16px;
        margin-bottom: 8px;
        overflow: hidden;
        box-shadow: 4px 4px 4px #00000026;
        border-radius: 10px;
        padding: 8px;
        border: 1px solid #00000026;
        p{
            margin-top: 10px;
            margin-bottom: 10px;
            word-break: break-all;
        }
        h4{
            margin:0;
        }
        &-property{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 15px;
            &-sender{
                flex: 1;
            }
            &-time{
                display: flex;
                flex-direction: row;
            }
        }
        &-attach{
            color: #304D30 !important;
            padding: 6px 8px;
            border-radius: 4px;
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.75;
            letter-spacing: 0.02857em;
            text-transform: uppercase;
            display: inline-block;
            &:hover{
                text-decoration: none;
                background-color: rgba(25, 118, 210, 0.04);
            }
            &-pdf{
                height: calc(100vh - 270px);
            }
        }
        &-button{
            width: 100%;
            padding-top: 10px !important;
            padding-bottom: 10px !important;
            background-color: #304D30 !important;
            border-radius: 10px !important;
            &:hover{
                background-color: #304D30 !important; 
            }
            &:active{
                background-color: #304D30 !important;
            }
            &[disabled]{
                background-color: #cccccc !important;
            }
        }
    }
}

.newsletter, .gestiones{
    &-home{
        display: flex;
        justify-content: center;
        figure{
            margin-top: 0;
            margin-block-end: 0;
            margin-inline-start: 0;
            margin-inline-end: 0;
            width: 100%;
            height: 448px;
            p{
                font-size: 20px;
                font-weight: 700;
                line-height: 27px;
            }
            img{
                width: 100%;
            }
            .btn{
                min-width: 299px;
                height: 56px;
                border-radius: 10px;
                margin-top: 100px;
            }
        }
    }
    &-dialog{
        &-close{
            margin-bottom: 10px;
            padding-top: 0;
            position: absolute;
            right: 6px;
            top: 6px;
            font-size: 20px;
            a{
                text-decoration: none;
                color: #304D30;
            }
        }
        &-content{
            &-title{
                font-family: 'Roboto'!important;
                font-style: normal!important;
                font-weight: 700!important; 
                font-size: 20px !important;
                line-height: 27px !important;
                text-align: center;
                letter-spacing: 0.17px !important;
                color: #000000 !important;
                margin-bottom: 30px !important;
                margin-top: 30px !important;
            }
            &-subtitle{
                margin: 0 0 30px 0;
            }
            &-fields{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                label{
                    font-family: "Roboto","Helvetica","Arial",sans-serif;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 11px;
                    color: #828282;
                    margin-bottom: 5px;
                }
                input{
                    width: 100%;
                    font-family: "Roboto","Helvetica","Arial",sans-serif;
                    box-sizing: border-box;
                    padding: 10px 10px 10px 0;
                    border-top: 0;
                    border-left: 0;
                    border-right: 0;
                    border-bottom: 1px solid #f2f2f2;
                    outline: none;
                    font-size: 16px;
                    margin-bottom: 22px;
                    &:focus{
                        border-bottom: 1px solid #000;
                    }
                    &::placeholder{
                        font-size: 16px;
                        color: #bdbdbd;
                        font-family: "Roboto","Helvetica","Arial",sans-serif;
                    }
                }
            }
            &-button{
                button{
                    background-color: #304D30;
                    border-radius: 10px;
                    padding: 15px 10px;
                    width: 100%;
                    border: 0;
                    color: #fff;
                    font-size: 16px;
                    font-family: "Roboto","Helvetica","Arial",sans-serif;
                    font-weight: 600;
                    cursor: pointer;
                    margin-bottom: 30px;
                }
            }
        }
        &-form{
            &-error{
                color: red;
            }
            &-info{
                text-align: center;
                color: #304D30;
            }
            &-inprocess{
                color: #304D30;
            }
            &-success{
                font-weight: bold;
                font-size: 1.17em;
                &.lss-top{
                    margin-top: 15px;
                }
            }
        }
    }
}

.container{
    position: relative;
    width: 100%;
    &-text{
        flex-direction: column;
        position: absolute;
        top: 25%;
        display: flex;
        justify-content: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        letter-spacing: 0.17px;
        color: #FFFFFF;
    }
}


.container .btn {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background: #FFFFFF;
    color: #304D30;
    font-size: 16px;
    padding: 12px 24px;
    border-radius: 10px;
    cursor: pointer;
    width: 90%;
  }

  .passwd{
    &-reset{
        &-figure{
            padding: 0;
            display: flex;
            justify-content: center;
        }
        img{
            width: 62px;
        }
        &-content{
            &-title{
                font-family: 'Roboto'!important;
                font-style: normal!important;
                font-weight: 700!important; 
                font-size: 20px !important;
                line-height: 27px !important;
                text-align: center;
                letter-spacing: 0.17px !important;
                color: #000000 !important;
            }
            input{
                outline: none !important;
                border-top-style: hidden;
                border-right-style: hidden;
                border-left-style: hidden;
                border-bottom-style: hidden;
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.2px;
                margin-top: 11px;
                padding: 0 0 13px 0;
                border-bottom: 1px solid #F2F2F2;
                width: -webkit-fill-available; 
            }
            &-email{
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                letter-spacing: 0.181818px;
                color: #828282;
                margin: 38px 0 0 0 ;
            }
            &-buttons{
                justify-content: center !important;
                margin-bottom: 26px;
            }
            &-button{
                padding: 18px 55px 18px 55px!important;
                background-color: #304D30 !important;
                border-radius: 10px !important;
                &:hover{
                    background-color: #304D30 !important; 
                }
                &:active{
                    background-color: #304D30 !important;
                }
                &[disabled]{
                    background-color: lightgrey !important;
                    cursor: default;
                }
            }
        }
    }
  }

.resetpwd{
    margin: 56px 0 0 20px !important;
    padding: 0 0 0 0 !important;
    &-cont{
        &-topInf{
            display: grid;
            margin-left: 17px;
            a{
                grid-column: 1/2;
                display: flex;
            }
            h1{
                grid-column: 2/3;
                font-size: 17px;
                font-weight: 600;
                line-height: 20px;
                margin-right: 150px;
            }
        }
    }
}

.resetearC{
    margin: 56px 0 0 20px !important;
    padding: 0 0 0 0 !important;
}

.cdc-profile-table input:focus{
    border-bottom: 1px solid #000;
}

.passwd-reset-content input:focus{
    border-bottom: 1px solid #000;
}

#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif;  width:600px;} 

.noMargin{
    margin: 0;
}

.textareaElement{
    &-gestiones{
        width: 300px;
        min-height: 17px;
        border: 1px solid #ccc;
        max-height: 150px;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

@media only screen and (max-width: 767px){
    .cdc{
        &-license{
            &-userT{
                &-checkform{
                    button{
                        margin-left: -100px;
                    }
                }
            }
        }
        &-install{
            &-button{
                &-google{
                    height: 46px !important;
                }
            }
            &-dialog{
                width: 100%;
                &-top, &-not{
                    h2{
                        font-size: 14px;
                    }
                    button{
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@keyframes bounceNotification {
    0%{transform: scale(1, 1)}
    50%{transform: scale(1.03, 1.03)}
    100%{transform: scale(1, 1)}
  }

.social-buttons{
    text-align: center;
}
.react-share__ShareButton {
    margin: 3px;
}