.cdc-logo{
    width: 100%;
}

.width100{
    width: 100%;
}

.loading-inline{
    width: fit-content;
    display: flex;
    align-items: center;
    p{
        padding-left: 20px;
    }
}

.hide{
    display: none;
}

.flex-container{
    display: flex;
}

.row-direction{
    flex-direction: row;
}

.row-directionImp{
    flex-direction: row !important;
}

.align-center{
    align-items: center;
}

.justifyContentEnd{
    justify-content: end;
}

.textAlign{
    text-align: center;
}

.justify-space{
    justify-content: space-between;
}

.noMargin{
    margin: 0;
}

.noMarginImp{
    margin: 0 !important;
}

.marginBottom40{
    margin-bottom: 40px;
}

.marginBottom10{
    margin-bottom: 10px;
}

.noPadding{
    padding: 0;
}

.noPaddingImp{
    padding: 0 !important;
}

.break-word{
    word-break: break-all;
}

.lateral-menu{
    width: 40%;
    .MuiDrawer-paper{
        width: 40%;
    }
}

.pdf-button{
    background-color: #304D30;
    border-radius: 10px;
    padding: 20px 10px;
    border: 0;
    color: #fff;
    font-size: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 20px;
    &.prev{
        margin-right: 5%;
    }
}

.react-pdf__Page{
    min-width: 100% !important;
    min-height: 100% !important;
}

.react-pdf__Page__canvas{
    width: 100% !important;
    height: 100% !important;
}

.react-pdf__Page__annotations{
    display: none !important;
}

.red-flag{
    border: 2px solid red !important;
    outline: none;
}


.reg{
    &-complete{
        &-title{
            margin-bottom: 50px !important;
        }
        &-button{
            margin-top: 30px !important;
            font-size: 16px !important;
            display: flex;
            color: white !important;
            text-decoration: none !important;
            width: 100%;
            border-radius: 10px;
            background-color: #304D30;
            height: 60px;
            align-items: center;
            justify-content: center;
        }
    }
}

.header-bar{
    background-color: #304D30 !important;
    height: 78px;
    .toolbar{
        display: flex;
        margin: auto 0 5px auto;
        width: 100%;
        box-sizing: border-box;
        &-items{
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            & > div{
                button{
                    //margin: 0;
                }
            }
        }
        &-breadcrumbs{
            display: flex;
            align-items: center;
                a{
                    font-family: "Roboto","Helvetica","Arial",sans-serif;
                    text-decoration: none;
                    color: #fff;
                    font-size: 15px;
                    font-weight: 400;
                }
        }
    }
}

.footer{
    margin-top: auto;
    background-color: #282b2e;
    color: #e8e8e8;
}

.wrapper-footer{
    max-width: 1410px;
    margin: 0 auto;
    padding-bottom: 11px;
    padding-top: 11px;
    padding: 11px 50px 11px 50px;
    width: 95% !important;
}

.footer-link{
    padding: 0 10px;
    a{
        color: #e8e8e8;
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
    }
}

.icon-linkedin-container{
    ul{
        list-style-type: none;
        margin: 0;
        padding-left: 10px;
    }
    li{
        line-height: 30px;
        width: 30px;
        height: 30px;
        font-size: 11px;
        border-radius: 300px;
        transition: all 0.3s ease-out;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            background-color: #419cca;

            a{
                color:#fff;
            }
        }

        a{
            color: #777777;
        }
    }
}

.icon-linkedin-li{
    line-height: 30px;
    width: 30px;
    height: 30px;
    font-size: 11px;
    border-radius: 300px;
    transition: all 0.3s ease-out;
    text-decoration: none;

    &:hover {
        background-color: #419cca;
    }
}

.resetpass{
    &-texto{
        display: grid;
        margin-left: 17px;
        a{
            grid-column: 1/2;
            display: flex;
        }
        h1{
            grid-column: 2/3;
            font-size: 17px;
            font-weight: 600;
            line-height: 20px;
            margin-right: 150px;
        }
    }
    &-form{
        &-code{
            padding-top: 24px;
            padding-bottom: 24px;
            h2{
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                color: #828282;
                margin-bottom: 20px;
            }
            input[type=number]{
                -moz-appearance: 'textfield';
            }
            input::-webkit-outer-spin-button{
                -webkit-appearance: 'none';
                margin: 0;
            }
            input::-webkit-inner-spin-button{
                -webkit-appearance: 'none';
                margin: 0
            }
            &-elemento{
                margin-bottom: 20px !important;
            }
            button{
                height: 60px;
                background-color: #304D30;
                color: #e8e8e8;
                border-radius: 10px;
                margin-top: 10px;
            }
            button:hover{
                background-color: #CB885C;
            }
        }
    }
}

.textoFinalC{
    text-align: center;
}

.resetpwdF{
    margin-top: 0;
    //position: absolute;
    bottom: 85px;
    h2{
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #828282;
        //margin-top: -180px;
        width: 100%;
    }
    &-tField{
        position: fixed;
        margin-top: 10px;
        width: 92%;
    }
    &-cod{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 170px;
        position: fixed;
        right: 38%;
        a{
            text-decoration: none;
            color: #828282;
        }
    }
    button{
        height: 60px;
        background-color: #304D30;
        color: #e8e8e8;
        border-radius: 10px;
        margin-top: 95px;
        position: fixed;
        width: 92%;
    }
    button:hover{
        background-color: #CB885C;
    }
    
}


@media only screen and (min-width: 768px){
    .hideDsk{
        display: none;
    }

    .pdf-button{
        background-color: #304D30;
        border-radius: 10px;
        padding: 20px 100px;
        border: 0;
        color: #fff;
        font-size: 25px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 600;
        cursor: pointer;
        margin-bottom: 60px;
        &.prev{
            margin-right: 5%;
        }
    }

    .pagina-contador{
        font-size: 30px;
    }
}

@media only screen and (max-width: 767px){
    .payment{
        h1{
            line-height: 36px !important;
            font-size: 26px !important;
        }
        ul{
            li{
                font-size: 18px !important;
            }
        }
    }

    .hideMv{
        display: none;
    }

    .wrapper-footer{
        width: 85%;
        max-width: 85%;
        margin: 0 auto;
        padding-left: 0;
        padding-right: 0;
        padding-top: 13px;
        padding-bottom: 13px;
    }

    .footer-nav div:first-child{
        padding-left: 0;
    }

    .footer-nav-container{
        width: 100%;
    }

    .footer-nav{
        justify-content: flex-start !important;
    }
}

.subscribe{
    &-newsletter{
        &-title{
            font-family: 'Roboto' !important;
            font-style: normal;
            font-weight: 600 !important;
            font-size: 17px ;
            line-height: 20px !important;
        }
        &-content{
            font-family: 'Roboto' !important;
            font-style: normal;
            font-weight: 600 !important;
        }
        &-button{
            color: #FFFFFF !important;
            background: #304D30 !important;
            border-radius: 10px !important;
        }
    }
}

.login{
    &-main{
        padding: 0 20px !important;
    }
    &-header{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        //margin-bottom: 40%;
        &-typography{
                height: 20px;
                font-family: 'Roboto' !important;
                font-style: normal;
                font-weight: 600 !important;
                font-size: 17px !important;
                line-height: 20px !important;
                text-align: center;
                letter-spacing: 0.218571px !important;
                margin-left: 18px !important;
        }
        &-backicon{
            cursor: pointer;
        }
        &-left{
            display: flex;
            align-items: center;
        }
        &-listicon{
            visibility: hidden;
        }
    }
    &-form{
        align-items: center;
        justify-content: center;
        //position: absolute;
        bottom: 0;
        padding-bottom: 24px;
        padding-top: 24px;
        &-progress{
            justify-content: center;
            align-items: center;
            display: flex;
            width: 100%;
            height: 50%; 
        }
        &-buttom{
            color: #FFFFFF !important;
            background: #304D30 !important;
            border-radius: 10px !important;
            padding-top: 20px !important;
            padding-bottom: 20px !important;
            padding-left: 33px !important;
            padding-right: 33px !important;
            width: 100%;
        }
        &-container{
            &-field{
                display: flex !important;
            }
        }
    }
}

.register{
    &-main{
        padding: 0 20px !important;
    }
}

.welcome{
    &-main{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    &-form{
        background:url("../../assets/ciervo1.svg") no-repeat;
    }
    &-body-v2 {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &-body{
        position: relative;
        border-radius: 15px;
        margin-top: -25px;
        background-color: #fff;
        padding: 20px 26px;        
        &-typography{
            font-family: 'Roboto' !important;
            font-style: normal;
            font-weight: 500 !important;
            font-size: 32px !important;
            line-height: 35px !important;
            text-align: center;
            letter-spacing: 0.4px !important;
            &-subtitle{
                font-family: 'Roboto' !important;
                font-style: normal;
                font-weight: 400 !important;
                font-size: 14px !important;
                line-height: 16px !important;
                text-align: center;
                letter-spacing: 0.175px !important;
                color: #828282;
                margin-top: 10px !important;
                margin-bottom: 31px !important;
            }
        }
        &-buttons{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
        }
        &-buttom{
            background: #304D30 !important;
            border-radius: 6px !important;
            color: #FFFFFF !important;
            padding-top: 16px !important;
            padding-bottom: 16px !important;
            padding-left: 35px !important;
            padding-right: 35px !important;
            width: 100%;
            margin: 0 10px !important;
            &-login{
                background: #304D30 !important;
                border-radius: 6px !important;
                color: #FFFFFF !important;
                padding-top: 16px !important;
                padding-bottom: 16px !important;
                padding-left: 45px !important;
                padding-right: 50px !important;
            }
        }
        &-logo{
            text-align: center;
            img{
                width: 200px;
            }
        }
        &-image{
            width: 100%;
            margin: 0;
            img{
                width: 100%;
                height: 55vh;
                object-fit: cover;
                object-position: right;
            }
        }
    }
}

//PARTE PÚBLICA
//==============================================

.public{
    &-iframe{
        height: calc(100vh);
    }
    &-footer{
        background-color: #619428;
        margin-top: auto;
        text-align: center;
        &-logo{
            margin-top: 50px;
            img{
                width: 273px;
            }
        }
        &-legal{
            background-color: #000;
            color:#828282;
            display: flex;
            justify-content: center;
            text-align: center;
            a{
                color:#FFF;
                margin-left: 3px;
                cursor: pointer;
                text-decoration: none;
            }
        }
        &-buttons{
            display: flex;
            justify-content: center;
            align-items: center;
            figure{
                margin-left: 10px;
                margin-right: 10px;
                img{
                    height: 32px;
                }
            }
        }
        &-img-google{
            height: 47px !important;
        }
        &-rrss{
            display: flex;
            justify-content: center;
            margin-bottom: 15px;
            figure{
                margin-left: 10px;
                margin-right: 10px;
            }
        }
    }
    &-header{
        background-color: #619428 !important;
        .button-join{
            text-transform: none;
            font-size: 17px;
            background-color: #FFFFFF;
            color: #000;
            box-shadow: none;
            border-radius: 10px;
            margin-left: 12px;
            &:hover{
                background-color: #F2F2F2;
            }
        }
        .icon-join{
            font-size: 28px;
        }
        &-box{
            display: flex;
            align-self: end;
            margin-bottom: 15px;
        }

        &-links{
            text-align: center;
            a{
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                margin-right: 12px;
                margin-left: 12px;
                text-decoration: none;
                color: #FFFFFF;
                &:hover{
                    text-decoration: underline;
                }
                p{
                    font-size: 24px;
                }
            }
        }
        &-linksb{
            a{
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                text-decoration: none;
                color: #000;
                &:hover{
                    text-decoration: underline;
                }
                p{
                    font-size: 17px;
                }
            }
        }
        &-buttonb-join{
            text-transform: none !important;
            background-color: #FFFFFF !important;
            color: #000 !important;
            height: 36px;
            font-family: "Roboto","Helvetica","Arial",sans-serif;
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.75;
            letter-spacing: 0.02857em;
            text-transform: uppercase;
            min-width: 64px;
            padding: 6px 16px;
            border-radius: 4px;
            border: 0;
            cursor: pointer;
            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            &:hover{
                box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
                background-color: #F2F2F2 !important;
            }
        }
    }
    &-rrss{
        display: flex !important;
        justify-content: center;
        margin-bottom: 32px;
        figure{
            margin-left: 8px;
            margin-right: 8px;
        }
    }
    &-logo{
        height: 100%;
    }
    &-toolbar{
        display: flex;
        justify-content: space-between;
        padding: 0 8% !important;
        &-logo{
            margin: 0;
            height: calc(100% - 15px);
            a{
                display: block;
                height: 100%;
            }
        }
    }
    &-managment, &-marketplace, &-magazine{
        padding-left: 8%;
        padding-right: 8%;
        margin-top: 40px;
        margin-bottom: 10px;
        padding-bottom: 40px;
        border-bottom: 2px solid #ccc;
        p{
            font-size: 20px !important;
            font-weight: 400 !important;
            line-height: 29px !important;
            margin-bottom: 5px !important;
        }
        h1{
            font-size: 40px;
            font-weight: 700;
            line-height: 50px;
            margin-bottom: 16px;
            text-align: center;  
        }
    }

    &-join{
        &-container{
            &-ventajas{
                font-size: 24px !important;
                font-weight: 600 !important;
                line-height: 35px !important;
                margin-bottom: 0;
            }
            font-size: 40px !important;
            font-weight: 700 !important;
            line-height: 50px !important;
            display: flex;
            padding-left: 8%;
            padding-right: 8%;
            margin: 40px 0 !important;
            h1{
                font-size: 40px;
                font-weight: 700;
                line-height: 50px;
                margin-bottom: 16px;
            }
            & > div{
                flex: 50%;
            }
            & > form{
                flex: 50%;
            }
            &-button{
                background-color: #25D366;
                padding: 8px 24px;
                font-size: 20px;
                font-weight: 400;
                border-radius: 40px;
                color: #fff;
                cursor: pointer;
                border: none;
                &:hover{
                    
                }
            }
            &-form{
                & > div{
                    margin-top: 40px;
                }
                label{
                    display: block;
                    font-size: 18px;
                    font-family: "Roboto","Helvetica","Arial",sans-serif;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: left;
                    margin-bottom: 10px;
                }
                input, textarea, select{
                    border: 1px solid #619428;
                    height: 48px;
                    padding: 0 10px;
                    font-size: 18px;
                    width: 100%;
                    border-radius: 4px;
                    box-sizing: border-box;
                }
                textarea{
                    height: 100px;
                    padding: 10px;
                    font-family: "Roboto","Helvetica","Arial",sans-serif;
                    max-width: 100%;
                    resize: vertical;
                }
                button{
                    background-color: #619428;
                    padding: 11px 24px;
                    font-size: 18px;
                    font-weight: 400;
                    border-radius: 40px;
                    color: #fff;
                    cursor: pointer;
                    border: none;
                    height: 48px;
                    margin-top: 10px;
                    &:hover{
                        background-color: #3f6316;
                    }
                }
            }
            &-text{
                margin-right: 56px;
            }
        }
        &-form{
            &-field{
                display: flex;
                justify-content: space-between;
                & > div{
                    &:first-of-type{
                        flex: 60%;
                        margin-right: 16px;
                    }
                }
            }
        }
        &-strong{
            font-size: 24px !important;
            font-weight: 700 !important;
            line-height: 40px !important;
            margin-bottom: 16px !important;
        }
        &-thin{
            font-size: 20px !important;
            font-weight: 400 !important;
            line-height: 29px !important;
            margin-bottom: 5px !important;
        }
        &-modal{
            &-error{
                &-content{
                    span{
                        display: block;
                    }
                }
                &-actions{
                    margin-bottom: 16px;
                    padding: 8px 24px !important;
                }
                &-button{
                    background-color: #304D30 !important;
                    padding: 8px 24px;
                    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
                    font-size: 17px !important;
                    border-radius: 40px !important;
                    color: #fff !important;
                    cursor: pointer !important;
                    border: none !important;
                    &:hover{
                        background-color: #CB885C !important;
                    }
                }
            }
        }
        &-field{
            line-height: 0;
            margin-bottom: 16px;
            text-align: left;
            &.c-box-1{
                margin-top: 20px;
                margin-bottom: 30px !important;
            }
            .checkbox{
                display: flex;
                align-items: start;

                input{
                    width: auto;
                    height: auto;
                    min-height: 16px;
                    min-width: 16px;
                }
                label{
                    margin: 0 0 0 7px;
                    font-size: 14px;
                    font-weight: 500;
                    a{
                        cursor: pointer;
                        text-decoration: none;
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    &-blog{
        &-pagination{
            margin-top: 40px;
            ul{
                justify-content: center;
                li{
                    &:first-child, &:last-child{
                        button{
                            background-color: #619428;
                            padding: 0px 9px;
                            border-radius: 0;
                            box-sizing: content-box;
                            &[disabled]{
                                background-color: #F2F2F2;
                            }
                        }
                    }
                    button{
                        background-color: #d9d9d9;
                        border-radius: 0;
                        font-weight: bold;
                        font-size: 17px;
                        &[aria-current=true]{
                            background-color: #619428;
                            color: #fff;
                        }
                    }
                }
            }
        }
        &-category{
            color: #5485B0;
            font-size: 12px !important;
            font-weight: 400 !important;
            letter-spacing: -0.25px !important;
            line-height: 18px !important;
        }
        &-search{
            margin-top: 32px;
            &-input{
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                font-size: 17px;
                width: 100%;
                max-width: 577px;
                margin: 0 auto;
                height: 40px;
                border-radius: 40px;
                background-color: #F2F2F2;
                display: flex;
                border: 1px solid transparent;
                &:focus-within{
                    border: 1px solid #315509;
                }
                & > div{
                    position: relative;
                    display: flex;
                    input[type=submit]{
                        background-image: url(../../assets/lupa.svg);
                        background-repeat: no-repeat;
                        height: 100%;
                        background-position: center;
                        padding-left: 50px;
                        cursor: pointer;
                    }
                }
                input, select{
                    border: 0;
                    background-color: #F2F2F2;
                    border-radius: 40px;
                    font-size: 17px;
                    outline: none;
                }
                input{
                    padding-left: 0;
                }
                select{
                    flex-grow: 1;
                    border-left: 1px solid #BDBDBD;
                    border-radius: 0px 40px 40px 0;
                    margin: 5px 0;
                    padding-left: 5px;
                    appearance: none;
                    cursor: pointer;
                    background-repeat: no-repeat;
                    background-position: top 12px right 10px;
                    background-image: url(../../assets/arrows.svg);
                    &:focus,&:active{
                        background-image: url(../../assets/invertedArrows.svg);
                    }
                }
            }
            
        }
        &-details{
            padding: 0 8%;
            text-align: center;
            margin-top: 40px;
            margin-bottom: 32px;
            &-title{
                margin-bottom: 48px;

                h1{
                    font-size: 40px;
                    font-weight: 700;
                    line-height: 50px;
                }
            }
            &-container{
                max-width: 577px;
                min-width: 577px;
                margin: 0 auto;
                text-align: left;
                &-content{
                    font-size: 17px;
                    line-height: 28px;
                }
                h2{
                    margin-bottom: 16px;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 20px;
                }
                p{
                    font-size: 17px;
                    line-height: 28px;
                }
                a{
                    text-decoration: none;
                }
                figure{
                    margin: 0 0 20px 0;
                    img{
                        object-fit: inherit;
                        height: 356px;
                        width: 100%;
                    }
                }
            }
            &-category{
                color: #5485B0;
                font-size: 12px !important;
                font-weight: 400 !important;
                letter-spacing: -0.25px !important;
                line-height: 28px !important;
            }
        }
        &-container{
            padding: 0 8%;
            text-align: center;
            margin-top: 40px;
            margin-bottom: 32px;
            &-list{
                margin-top: 0 !important;
                figure{
                    margin: 0 0 10px 0;
                    img{
                        width: 100%;
                        height: 170px;
                        object-fit: cover;
                    }
                }
                h2,p{
                    text-align: left;
                }
                h2{
                    font-size: 20px;
                    font-weight: 700;
                    margin-bottom: 8px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                a{
                    text-align: left;
                }
            }
            &-card{
                position: relative;
                a{
                    text-decoration: none;
                    color:#000;
                }
            }
            &-item{
                text-decoration: none !important;
                color: inherit !important;
            }
            &-read{
                position: absolute;
                bottom: 0;
                a{
                    text-decoration: none !important;
                    color: #315509 !important;
                }
            }
            &-text{
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                font-weight: 400;
                font-size: 17px;
                max-width: 1020px;
                margin: 0 auto 32px auto;
                p{
                    font-size: 17px;
                    margin: 0 auto;
                    max-width: 840px;
                }
            }
            &-content{
                line-height: 28px;
                margin-bottom: 30px;
                font-size: 17px;
                font-weight: 400;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                text-align: left;
                p{
                    margin: 0;
                }
            }
            h1{
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                font-size: 40px;
                font-weight: 700;
                line-height: 50px;
                margin-bottom: 16px;
            }
        }
    }

    &-section{
        &-bold{
            font-weight: bold !important;
        }
        &-button{
            background-color: #619428;
            padding: 8px 24px;
            font-family: "Roboto","Helvetica","Arial",sans-serif;
            font-size: 17px;
            border-radius: 10px;
            color: #fff;
            cursor: pointer;
            border: none;
            &:hover{
                background-color: #3f6316;
            }
        }
        &-member{
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            position: relative;
            min-height: 100%;
            &-box{
                position: absolute;
                max-width: 850px;
                top: 100px;
                left: 8%;
                h1,p{
                    color: #fff;
                    font-family: "Roboto","Helvetica","Arial",sans-serif;
                }
                h1{
                    font-size: 40px;
                    font-weight: 700;
                    margin-bottom: 16px;
                }
                p{
                    font-size: 20px;
                    font-weight: 500;
                }
            }
            &-text{
                margin-bottom: 40px;
            }
            ul{
                list-style: none;
                li{
                    font-family: "Roboto","Helvetica","Arial",sans-serif;
                    font-size: 30px;
                    color: #fff;
                    font-weight: bold !important;
                }
            }
            &-button{
                background-color: #FFFFFF;
                padding: 12px 36px;
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                font-size: 40px;
                border-radius: 10px;
                color: #000;
                cursor: pointer;
                border: none;
                &:hover{
                    background-color: #F2F2F2;
                }
                &.banner{
                    font-size: 24px;
                    font-weight: 500;
                }
            }
        }
        &-about{
            text-align: center;
            padding: 40px 0 8% 0 !important;
            max-width: 1000px;
            &-box{
                margin-bottom: 50px;
                h1,p{
                    color: #000;
                    font-family: "Roboto","Helvetica","Arial",sans-serif;
                }
                h1{
                    font-size: 40px;
                    font-weight: 700;
                    margin-bottom: 16px;
                }
                p{
                    font-size: 17px;
                    font-weight: 400;
                    line-height: 28px;
                    letter-spacing: -1.5;
                }
            }
            &-button{
                background-color: #619428;
                padding: 8px 24px;
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                font-size: 20px;
                border-radius: 10px;
                color: #fff;
                cursor: pointer;
                border:none;
                &:hover{
                    background-color: #3f6316;
                }
            }
        }
        &-offers{
            margin-bottom: 80px;
            margin-top: 40px;
            padding-left: 8% !important;
            padding-right: 8% !important;
            &-box{
                h1,h2,p{
                    color: #000;
                    font-family: "Roboto","Helvetica","Arial",sans-serif;
                }
                h1{
                    text-align: center;
                    font-size: 40px;
                    font-weight: 700;
                }
                h2{
                    font-size: 24px;
                    font-weight: 700;
                    margin-bottom: 8px;
                }
                p{
                    font-size: 17px;
                    font-weight: 400;
                    line-height: 28px;
                    margin-bottom: 52px;
                }
                figure{
                    margin: 0;
                    img{
                        width:100%;
                        margin-bottom: 24px;
                    }
                }
                a{
                    color: inherit;
                    text-decoration: none;
                    &:hover{
                        color:#619428;
                    }
                }
            }
            &-items{
                padding-top: 16px !important;
                position: relative;
                margin-bottom: 20px !important;
                h2{
                    font-size: 24px;
                    font-weight: 700;
                    margin-bottom: 8px;
                    text-align: center;
                }
                &-image{
                    position: relative;
                    width: 100%;
                    img{
                        width: 100%;
                        height: auto;
                    }
                    h1,h2{
                        position: absolute;
                        top: 75%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        color: #ffffff;
                        font-family: "Roboto","Helvetica","Arial",sans-serif;
                        width: 100%;
                        text-align: center;
                        background-color: #619428;
                        font-size: 20px;
                        font-weight: 300;
                    }
                }
            }
        }
        &-raffles{
            margin-bottom: 50px;
            padding-left: 8% !important;
            padding-right: 8% !important;
            &-grid{
                background-position: 0px;
                background-size: cover;
                position: relative;
                //height: 500px;
                background-repeat: no-repeat;
                &-container{
                    padding: 40px 34px;
                    //position:absolute;
                    top: 80px;
                    left: 90px;
                }
            }
            &-box{
                h1,p{
                    color: #fff;
                    font-family: "Roboto","Helvetica","Arial",sans-serif;
                }
                h1{
                    font-size: 40px;
                    font-weight: 700;
                    line-height: 50px;
                    margin-bottom: 15px;
                }
                p{
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 35px;
                }
            }
            &-text{
                max-width: 650px;
                margin-bottom: 30px;
            }
            &-button{
                font-weight: 400;
                background-color: #619428;
                padding: 8px 16px;
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                font-size: 20px;
                border-radius: 10px;
                color: #fff;
                cursor: pointer;
                border: none;
                &:hover{
                    background-color: #3f6316;
                }
            }
        }
        &-blog{
            margin-bottom: 50px;
            padding-right: 8% !important;
            padding-left: 8% !important;
            .public-category{
                color: #5485B0;
                font-size: 12px !important;
                font-weight: 400 !important;
                letter-spacing: -0.25px !important;
                line-height: 18px !important;
            }
            &-box{
                h1,h2,p{
                    color: #000;
                    font-family: "Roboto","Helvetica","Arial",sans-serif;
                }
                h1{
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 50px;
                    text-align: center;
                    margin-bottom: 20px;
                }
                h2{
                    font-size: 20px;
                    font-weight: 700;
                    margin-bottom: 8px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                p{
                    margin: 0;
                }
                figure{
                    margin: 0 0 10px 0;
                    img{
                        width: 100%;
                        height: 170px;
                        object-fit: cover;
                    }
                }
                a{
                    color: inherit;
                    text-decoration: none;
                    &:hover{
                        color:#619428;
                    }
                }
            }
            &-read{
                position: absolute;
                bottom: 0;
                a{
                    text-decoration: none !important;
                    color: #315509 !important;
                }
            }
            &-item{
                text-decoration: none !important;
                color: inherit !important;
            }
            &-container{
                &-content{
                    margin-bottom: 30px;
                    line-height: 28px;
                    font-size: 17px;
                    font-weight: 400;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
            }
            &-card{
                position: relative;
            }
        }
        &-join{
            //height: 800px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            position: relative;
            //max-height: 480px;
            padding: 4% 8% !important;
            &-box{
                h1,p{
                    color: #fff;
                    font-family: "Roboto","Helvetica","Arial",sans-serif;
                }
                h1{
                    font-weight: 900;
                    font-size: 40px;
                    line-height: 50px;
                    margin-bottom: 16px;
                }
                p{
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 28px;
                }
            }
            &-text{
                margin-bottom: 25px;
            }
            &-button{
                background-color: #619428;
                padding: 8px 16px;
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                font-size: 20px;
                font-weight: 400;
                border-radius: 10px;
                color: #fff;
                cursor: pointer;
                border:none;
                &:hover{
                    background-color: #3f6316;
                }
            }
        }
        &-contact{
            padding: 0 !important;
            &-error{
                color: red;
                font-family: "Roboto","Helvetica","Arial",sans-serif;
            }
            &-box{
                display:flex;
                height: 870px;
                figure{
                    margin: 0 !important;
                    width: 100%;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                &-form{
                    width: 100%;
                    background-color: #fff;
                    margin-top: 0;
                    padding: 32px;
                    form{
                        width: 90%;
                        h2,p{
                            color: #000;
                            font-family: "Roboto","Helvetica","Arial",sans-serif;
                        }
                        h2{
                            font-weight: 700;
                            font-size: 40px;
                            line-height: 50px;
                            margin-bottom: 26px;
                        }
                        form{
                            padding: 24px 32px;
                            width: 75%;
                            margin: 0;
                        }
                        button{
                            background-color: #619428;
                            padding: 12px;
                            margin-top: 15px;
                            &:hover{
                                background-color: #3f6316;
                            }
                        }
                    }
                }
            }
            &-input{
                text-align: initial;
                label{
                    display: block;
                    font-size: 18px;
                    font-family: "Roboto","Helvetica","Arial",sans-serif;
                    font-weight: 400;
                    line-height: 24px;
                    margin-bottom: 8px;
                }
                input, textarea{
                    border: 1px solid #619428;
                    height: 56px;
                    padding: 0 10px;
                    font-size: 18px;
                    width: 100%;
                    border-radius: 4px;
                    box-sizing: border-box;
                    margin-bottom: 16px;
                }
                textarea{
                    height: 100px;
                    padding: 10px;
                    font-family: "Roboto","Helvetica","Arial",sans-serif;
                    max-width: 100%;
                    resize: vertical;
                }
            }
        }
        &-joinmembers{
            &-box{
                margin: 0 !important;
                height: 100%;
                align-items: center;
                width: 100% !important;
                h1,p{
                    color: #FFFFFF;
                    font-family: "Roboto","Roboto",sans-serif;
                }
                h1{
                    text-align: left;
                    padding-right: 36px;
                    font-size: 40px;
                    font-weight: 700;
                    line-height: 60px;
                    margin-bottom: 34px;
                    display: flex;
                    flex-direction: row-reverse;
                }
                p{
                    text-align: end;
                    font-size: 50px;
                    font-weight: 400;
                    line-height: 47px;
                    margin-bottom: 60px;
                    margin-left: 70px;
                }
                figure{
                    img{
                        width:100%;
                    }
                }
                &-appd, &-appi{
                    padding: 0 !important;
                }
                &-appd{
                    padding: 90px 8%!important;
                    &-sub{
                        display: flex;
                        flex-direction: column;
                        max-width: 900px;
                        margin-left: auto;
                        p{
                            text-align: left;
                            font-size: 30px;
                            font-weight: 300;
                            line-height: 37px;
                            margin-bottom: 34px;
                        }
                    }
                    &-sum{
                        display: flex;
                        flex-direction: row-reverse;
                        align-items: end;
                    }
                }
                &-appi{
                    height: 100%;
                    figure{
                        height: 100%;
                        margin: 0;
                        img{
                            object-fit: cover;
                            height: 100%;
                        }
                    }
                }
                &-stores{
                    display: flex !important;
                    flex-direction: column !important;
                    margin-bottom: 60px;
                    figure{
                        margin: 0;
                    }
                    p{
                        text-align: left;
                        font-size: 28px;
                        font-weight: 600;
                        line-height: 47px;
                        margin-bottom: 34px;
                        margin-left: 0;
                        margin-right: 0;
                    }
                    &-links{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                    }
                    .apple-store{
                        margin-right: 12px;
                        .public-footer-img-google{
                            height: auto !important;
                        }
                    }
                    .google-store{
                        img.public-footer-img-google{
                            height: 90px !important;
                        }
                    }
                }
            }
        }
        &-laptopjoin{
            display: flex;
            height: 500px !important;
            padding-right: 8%;
            &-imagen{
                img{
                    margin-top: 9px;
                    width: 100%;
                    height: -webkit-fill-available;
                }
            }
            &-text{
                max-width: 576px;
                margin-left: -13%;
                h1,p{
                    color: #000;
                    font-family: "Roboto","Roboto",sans-serif;
                    margin: 0;
                }
                h1{
                    font-weight: 700;
                    font-style: normal;
                    font-size: 24px;
                    line-height: 50px;
                    letter-spacing: -0.015em;
                    margin-top: 60px;
                }
                p{
                    font-weight: 400;
                    font-style: normal;
                    font-size: 17px;
                    line-height: 28px;
                    letter-spacing: -0.015em;
                    max-width: 576px;
                    margin-bottom: 24px;
                }
                button{
                    background-color: #304D30;
                    font-family: "Roboto","Helvetica","Arial",sans-serif;
                    font-size: 17px;
                    font-weight: 400;
                    border-radius: 40px;
                    width: 158px;
                    height: 36px;
                    color: #fff;
                    cursor: pointer;
                    border:none;
                }
                button:hover{
                    background-color: #CB885C;
                }
            }
        }
        &-phonejoin{
            position: relative;
            padding: 134px 8% 134px 8%;
            height: 500px;
            background-color: #f1f1f1;
            background-image: url(../../assets/phonej.svg);
            background-repeat: no-repeat;
            background-position: bottom 0 left calc(80% + 150px);
            &-text{
                h1,p{
                    font-family: "Roboto","Roboto",sans-serif;
                    margin: 0;
                }
                h1{
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 50px;
                    letter-spacing: -0.015em;
                }
                p{
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 28px;
                    letter-spacing: -0.015em;
                    max-width: 576px;
                    margin-bottom: 24px;
                }
                button{
                    background-color: #304D30;
                    font-family: "Roboto","Helvetica","Arial",sans-serif;
                    font-size: 17px;
                    font-weight: 400;
                    border-radius: 40px;
                    width: 158px;
                    height: 36px;
                    color: #fff;
                    cursor: pointer;
                    border:none;
                }
                button:hover{
                    background-color: #CB885C;
                }
            }
        }
        &-infodogs{
            padding: 40px 8% 0 8%;
            position: relative;
            &-middletext{
                display: grid;
                h1,p{
                    font-family: "Roboto","Roboto",sans-serif;
                    margin: 0;
                    text-align: center;
                }
                h1{
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 50px;
                    letter-spacing: -0.015em;
                    margin-bottom: 10px;
                }
                p{
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 28px;
                    letter-spacing: -0.015em;
                    margin-bottom: 24px;
                }
                button{
                    background-color: #304D30;
                    font-family: "Roboto","Helvetica","Arial",sans-serif;
                    font-size: 17px;
                    font-weight: 400;
                    border-radius: 40px;
                    line-height: 19.92px;
                    width: 160px;
                    height: 36px;
                    gap: 8px;
                    color: #fff;
                    cursor: pointer;
                    border:none;
                    justify-self: center;
                    margin-bottom: 95px;
                }
                button:hover{
                    background-color: #CB885C;
                }
            }
            &-triptych{
                display: flex;
                min-height: 418px;
                h2,p{
                    font-family: "Roboto","Roboto",sans-serif;
                    margin: 0;
                }
                h2{
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 50px;
                    letter-spacing: -0.015em;
                }
                ul{
                    margin: 0;
                    padding: 0 0 0 27px;
                }
                ul li{
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 28px;
                    letter-spacing: -0.015em;
                    max-width: 359px;
                }
                figure{
                    position: absolute;
                    top: 64%;
                    left: 50%;
                    transform: translate(-54.5%, -50%);
                }
                &-sectionB{
                    margin-left: 40%;
                }
            }
        }
    }
    &-related{
        margin-bottom: 50px;
        padding-right: 8% !important;
        padding-left: 8% !important;
        .public-category{
            color: #5485B0;
            font-size: 12px !important;
            font-weight: 400 !important;
            letter-spacing: -0.25px !important;
            line-height: 18px !important;
        }
        &-none{
            margin-top: 20px;
        }
        &-box{
            h1,h2,p{
                color: #000;
                font-family: "Roboto","Helvetica","Arial",sans-serif;
            }
            h1{
                font-weight: 700;
                font-size: 40px;
                line-height: 50px;
                text-align: center;
                margin-bottom: 20px;
            }
            h2{
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 8px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            figure{
                margin: 0 0 10px 0;
                img{
                    width: 100%;
                    height: 170px;
                    object-fit: cover;
                }
            }
            a{
                color: inherit;
                text-decoration: none;
                &:hover{
                    color:#304D30;
                }
            }
            &-container{
                margin-bottom: 30px;
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
            }
        }
        &-read{
            position: absolute;
            bottom: 0;
            a{
                text-decoration: none !important;
                color: #3f6316 !important;
            }
        }
        &-item{
            text-decoration: none !important;
            color: inherit !important;
        }
        &-card{
            position: relative;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 900px){
    .public{
        &-header{
            &-box{
                &-md{
                    align-self: end;
                    margin-bottom: 15px;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1190px){
    .public{
        &-footer{
            &-legal{
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
        &-toolbar{
            padding: 0 1% !important;
        }
        &-section{
            &-joinmembers{
                padding: 0 8% 0 8% !important;
                &-box{
                    display: block !important;
                    p{
                        margin: 0 auto 60px auto;
                    }
                    &-appd{
                        max-width: 100% !important;
                        padding: 60px 0 !important;
                        &-sum{
                            display: block;
                        }
                    }
                    &-appi{
                        max-width: 100% !important;
                        height: auto;
                        margin: 0 auto;
                        figure{
                            height: auto;
                        }
                    }
                    &-stores{
                        justify-content: center;
                    }
                }
            }
            &-laptopjoin{
                padding-left: 8%;
                padding-right: 8%;
                &-imagen{
                    img{
                        margin-top: -15%;
                    }
                }
                &-text{
                    margin-left: 0%;
                    h1{
                        margin-top: 10px;
                        text-align: center;
                    }
                    p{
                        text-align: center;
                    }
                    button{
                        width: 100%;
                        margin-bottom: 15%;
                    }
                    button:hover{
                        background-color: #CB885C;
                    }
                }
            }
            &-phonejoin{
                position: unset;
                padding: 4% 8% 0 8%;
                height: 500px;
                background-color: #f1f1f1;
                background-size: 75%;
                background-position:bottom 0 left calc(100% + 75px);
                &-text{
                    h1,p{
                        font-family: "Roboto","Roboto",sans-serif;
                        margin: 0;
                        max-width: 400px;
                    }
                    h1{
                        text-align: left;
                    }
                    p{
                        text-align: left;
                    }
                    button{
                        margin-top: 44px;
                        margin-left: 100px;
                        width: 200px;
                        margin-bottom: 44px;
                    }
                }
            }
            &-infodogs{
                background-image: url(../../assets/perro-telefono.svg);
                background-repeat: no-repeat;
                background-size: 45%;
                background-position: top 10% left 100%;
                &-middletext{
                    display: grid;
                    h1,p{
                        text-align: center;
                        max-width: 50%;
                    }
                    button{
                        margin-bottom: 6%;
                        justify-self: unset;
                        width: 25%;
                        margin-left: 12%;
                    }
                }
                &-triptych{
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    min-height: 380px;
                    margin-top: 0;
                    h2,p{
                        font-family: "Roboto","Roboto",sans-serif;
                        margin: 0;
                    }
                    h2{
                        
                    }
                    ul{
                        margin: 0;
                        padding: 0 0 0 27px;
                    }
                    ul li{
                        max-width: 100%;
                    }
                    figure{
                        display: none;
                    }
                    &-sectionA{
                        grid-column-start: 1;
                    }
                    &-sectionB{
                        grid-column-start: 2;
                        margin-left: 20px;
                        
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .public{
        &-footer{
            &-legal{
                padding-left: 1rem;
                padding-right: 1rem;
            }
            &-logo{
                img{
                    width: 100%;
                    max-width: 273px;
                }
            }
        }
        &-header{
            max-height: 64px;
        }
        &-toolbar{
            padding: 0 10px !important;
        }
        &-hide-mv{
            display: none;
        }
        &-section{
            &-member{
                background-position: right;
                padding-left: 25px !important;
                padding-right: 25px !important;
                padding-top: 45px;
                &-box{
                    position: relative;
                    top: inherit;
                    left: inherit;
                    h1{
                        font-size: 32px;
                    }
                    p{
                        font-size: 18px;
                    }
                    li{
                        font-size: 18px !important; 
                    }
                }
                &-button{
                    font-size: 18px;
                }
            }
            &-about{
                padding: 40px 24px 60px 24px !important;
                &-box{
                    h1{
                        font-size: 32px;
                    }
                }
                &-button{
                    font-size: 18px;
                }
            }
            &-offers{
                padding: 0 24px !important;
                margin-bottom: 40px;
                &-box{
                    h1{
                        font-size: 32px;
                    }
                }
                &-items{
                    margin-bottom: 20px !important;
                }
            }
            &-raffles{
                padding-left: 0 !important;
                padding-right: 0 !important;
                &-grid{
                    &-container{
                        position: initial;
                        padding-top: 30px;
                        padding-left: 24px;
                        padding-right: 24px;
                        h1{
                            font-size: 32px;
                        }
                        p{
                            font-size: 18px;
                        }
                    }
                }
                &-button{
                    font-size: 18px;
                }
            }
            &-blog{
                padding-left: 24px !important;
                padding-right: 24px !important;
                h1{
                    font-size: 32px;
                }
            }
            &-join{
                min-height: inherit;
                max-height: inherit;
                padding: 40px 24px !important;
                &-box{
                    position: initial;
                    h1{
                        font-size: 32px;
                    }
                    p{
                        font-size: 18px;
                    }
                }
                &-button{
                    font-size: 18px;
                }
            }
            &-contact{
                &-box{
                    display: block;
                    height: 100%;
                    &-form{
                        width: auto;
                        form{
                            width: 100%;
                            h2{
                                font-size: 32px;
                            }
                        }
                    }
                }
            }
            &-joinmembers{
                padding: 40px 24px !important;
                &-box{
                    display: block !important;
                    h1,p{
                        text-align: center;
                    }
                    h1{
                        font-size: 32px;
                        margin-bottom: 25px;
                        line-height: 36px;
                    }
                    p{
                        font-size: 20px;
                        line-height: 34px;
                        margin-bottom: 30px;
                        margin-left: 0 !important;
                        line-height: 1.5;
                        letter-spacing: 0.00938em;
                    }
                    &-appd{
                        padding: 0 !important;
                        background-position: right;
                        padding-left: 25px !important;
                        padding-right: 25px !important;
                        padding-top: 45px;
                        &-sum{
                            display: block !important;
                        }
                    }
                    &-appi{
                        figure{
                            height: auto;
                        }
                    }
                    &-stores{
                        justify-content: center;
                        margin-top: 50px;
                        .apple-store{
                            img{
                                height: 42px;
                                &.cdc-install-button-google{
                                    height: 60px !important;
                                }
                                &.public-footer-img-google{
                                    height: 56px !important;
                                }
                            }
                        }
                        .google-store{
                            img{
                                &.public-footer-img-google{
                                    height: 56px !important;
                                }
                                height: 56px;
                            }
                        }
                    }
                }
            }
            &-laptopjoin{
                padding-left: 24px;
                padding-right: 24px;
                display: grid;
                &-imagen{
                    grid-row-start: 2;
                    margin-bottom: 50px;
                    img{
                        margin-top: -15%;
                    }
                }
                &-text{
                    max-width: 100%;
                    margin-left: 0%;
                    display: grid;
                    h1{
                        margin-top: 10px;
                        text-align: center;
                    }
                    p{
                        max-width: 100%;
                        text-align: center;
                    }
                    button{
                        margin-bottom: 15%;
                        justify-self: center;
                    }
                    button:hover{
                        background-color: #CB885C;
                    }
                }
            }
            &-phonejoin{
                position: unset;
                padding: 4% 24px 0 24px;
                background-position: bottom 0 left calc(100% + 50px);
                background-size: 70%;
                &-text{
                    h1,p{
                        margin: 0;
                        max-width: 400px;
                    }
                    h1{
                        text-align: left;
                    }
                    p{
                        text-align: left;
                    }
                    button{
                        margin-top: 60px;
                        margin-bottom: 50px;
                        margin-left: 27px;
                        width: 40%;
                    }
                }
            }
            &-infodogs{
                padding: 4% 24px 4% 24px;
                background-image: none;
                &-middletext{
                    display: grid;
                    h1,p{
                        text-align: center;
                        max-width: 100%;
                    }
                    button{
                        margin-bottom: 6%;
                        justify-self: unset;
                        margin-left: 0;
                        justify-self: center;
                    }
                }
                &-triptych{
                    display: grid;
                    grid-template-rows: 1fr 1fr;
                    grid-template-columns: none;
                    min-height: auto;
                    margin-top: 0;
                    margin-bottom: 25px;
                    h2,p{
                        font-family: "Roboto","Roboto",sans-serif;
                        margin: 0;
                    }
                    ul{
                        margin: 0;
                        padding: 0 0 0 27px;
                    }
                    ul li{
                        max-width: 100%;
                    }
                    figure{
                        display: none;
                    }
                    &-sectionA{
                        grid-row-start: 1;
                        grid-column-start: 1;
                        grid-column-end: 3;
                        margin-bottom: 6%;
                    }
                    &-sectionB{
                        grid-row-start: 2;
                        grid-column-start: 1;
                        grid-column-end: 3;
                        margin-left: 0px;
                    }
                }
            }
        }
        &-blog{
            &-details{
                &-container{
                    min-width: inherit;
                }
            }
            &-container{
                padding-left: 24px;
                padding-right: 24px;
                &-text{
                    h1{
                        font-size: 32px;
                    }

                }
            }
            &-search{
                &-input{
                    font-family: inherit;
                    font-size: inherit;
                    width: inherit;
                    max-width: inherit;
                    margin: inherit;
                    height: inherit;
                    border-radius: inherit;
                    background-color: inherit;
                    display: inherit;
                    border: inherit;
                    position: inherit;
                    outline: none;
                    margin-bottom: inherit;
                    &:focus-within{
                        border: none;
                    }
                    & > div{
                        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                        font-size: 17px;
                        width: 100%;
                        max-width: inherit;
                        margin: 0 auto;
                        height: 40px;
                        border-radius: 40px;
                        background-color: #F2F2F2;
                        display: flex;
                        border: 1px solid transparent;
                        position: relative;
                        outline: none;
                        margin-bottom: 16px;
                        &:focus-within{
                            border: 1px solid #304D30;
                        }
                        input[type=text]{
                            width: 100%;
                            padding-right: 20px;
                        }
                    }
                    &-select{
                        padding: 0 20px !important;
                        background-position: top 15px right 15px !important;
                        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                        width: 100%;
                        max-width: inherit;
                        height: 40px;
                        border-radius: 40px !important;
                        display: flex;
                        border: 1px solid transparent !important;
                        position: relative;
                        &:focus,&:active{
                            background-image: url(../../assets/invertedArrows.svg);
                        }
                        &:focus-within{
                            border: 1px solid #304D30;
                        }
                    }
                }
            }
        }
        &-managment, &-magazine, &-marketplace{
            display: block;
            padding-left: 16px;
            padding-right: 16px;
        }
        &-join{
            &-container{
                display: block;
                padding-left: 16px;
                padding-right: 16px;
                h1{
                    font-size: 32px;
                }
                &-text{
                    margin-bottom: 40px;
                }
                &-button{
                    font-size: 18px;
                }
            }
            &-strong{
                font-size: 20px !important;
                line-height: 34px !important;
            }
            &-thin{
                font-size: 18px !important;
            }
            &-form{
                &-field{
                    & > div{
                        &:first-child{
                            flex: auto;
                        }
                    }
                }
            }
        }
    }
}
.invite{
    &-section{
     margin-top: 24px;
     align-items: center;
     display: flex;
     flex-direction: column;
    }
 
    &-button{
     background-color: #304D30 !important;
     color: #fff !important;
     font-family: "Roboto", "Helvetica", "Arial", sans-serif;
     border-radius: 10px;
     &:hover{
         background-color: #CB885C !important;
     }
    }
    
 }

 .payment{
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 8%;
    &.installed{
        padding: 0;
    }
    h1{
        font-family: "Roboto","Helvetica","Arial",sans-serif;
        font-weight: 700;
        line-height: 42px;
        margin: 0 0 2% 0;
        text-align: center;
    }
    ul{
        border: 2px solid #dbd9d9;
        padding: 5%;
        border-radius: 30px;
        list-style-type: none;
        li{
            font-size: 20px;
        }
    }
 }

//PARTE BACKOFFICE
//==============================================

.back-appbar{
    background-color: #5485B0 !important;
    height: 70px;
    padding: 0%
}

.back-logo{
    height: 70px;
    background-color: #e8e8e8;
}

.back-layout{
    margin-top: 30px;
    //margin-left: 10px;
    //margin-right: 10px;
}

.RaSidebar-fixed{
    min-width: 150px;
    position: fixed;
    width: 10%;
}
/*
.RaLayout-content{
    margin-left: 60px;
}
*/

.back{
    &-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 70px;
        align-items: center;
        &-left{
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: 70px;
        }
    }
}

.back-logout{
    height: 70px;
}


.back-user-list-buttons{
    display: flex !important;
    justify-content: space-evenly !important;
    margin-bottom: 10px !important;
    margin-top: 10px!important;
}

.edicion{
    &-sorteos{
        &-inputs{
            display: flex !important;
            flex-direction: row!important;
            width: 100%;
            justify-content: space-between;
            &-1{
                width: 100%;
                margin-right: 2%;
            }
            &-2{
                width: 100%;
                margin-left: 2%;
            }
        }
    }
    &-user{
        &-inputs{
            display: flex !important;
            flex-direction: row!important;
            width: 100%;
            &-1{
                display: flex!important;
                flex-direction: column!important;
                margin-right: 2%;
                margin-left: 5%;
                width: inherit;
            }
            &-2{
                display: flex!important;
                flex-direction: column!important;
                width: inherit;
                margin-right: 5%;
            }
        }
    }
    &-ticket{
        &-inputs{
            display: flex;
            flex-direction: column;
            width: 100%;
            &-0{
                margin-bottom: 10px;
            }
            &-1{
                display: flex;
                flex-direction: column;
                width: inherit;
                justify-content: space-between;
                &-2{
                    display: flex;
                    flex-direction: row;
                    width: inherit;
                    &.row{
                        margin-bottom: 20px;
                    }
                    .row{
                        margin-left: 2%;
                        &:first-child{
                            margin: 0;
                        }
                    }
                }
            }
            &-2{
                display: flex;
                flex-direction: row;
                width: inherit;
                margin-bottom: 24px;
                justify-content: space-between;
            }
            &-typo{
                margin-bottom: 16px!important;
                font-weight: 600!important;
            }
        }
        &-fecha{
            color: #000!important;
        }   
        &-remitente{
            color: #000!important;
        }
    }
    &-title{
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
        padding-bottom: 10px;
        align-items: center;
        background-color:#fff;
        border-bottom: 2px solid #f2f2f2;
        width: 100%;
        &-details{
            margin: 0;
            font-family: "Roboto","Helvetica","Arial",sans-serif;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.43;
            letter-spacing: 0.01071em;
        }
        &-status{
            border: 1px solid;
            border-radius: 5px;
            margin-right: 16px !important;
            padding: 5px;
            &.cerrada{
                background-color: #d32f2f
            }
            &.nuevo, &.activo{
                background-color: #00E753
            }
            &.revision{
                background-color: #FFFC37;
            }
            &.celebrado{
                background-color: rgba(0, 0, 0, 0.5);
            }
        }
        &-titulo{
            font-weight:600 !important;
            font-size:1rem !important;
            margin-right:16px !important;
        }
        &-category{
            font-weight:600 !important;
            font-size:1rem !important;  
        }
        &-id{
            font-weight:500 !important;
            font-size:1rem !important;
        }
        &-fecha{
            font-weight:600 !important;
            font-size:1rem !important;
            margin-right: 10px!important;
        }
    }

    &-id{
        display: flex;
        flex-direction: row;
        align-self: center;
    }
}

.mensajes{
    display: flex;
    flex-direction: row;
    width: auto;
}

.container-ticket{
    width: 90%;
    display: flex;
    justify-content: space-between;
}

.container-edit-ticket{
    width: 100%;
    display: flex;
    justify-content: space-around;
    &-rich{
        width: 100%;
        .ra-input-mensaje{
            align-items: start;
        }
        .RaRichTextInput-editorContent .ProseMirror{
            padding: 10px 15px !important;
            p{
                margin: 0 !important;
            }
        }
    }
}
.richTex{
    width: 100%;
}

#mensaje-label{
    width: 100%;
}

.button{
    &-toolbar{
        font-family: "Roboto","Helvetica","Arial",sans-serif !important;
        font-weight: 500 !important;
        font-size: 0.875rem !important;
        line-height: 1.75 !important;
        letter-spacing: 0.02857em !important;
        text-transform: uppercase !important;
        min-width: 64px !important;
        padding: 6px 16px !important;
        border-radius: 4px !important;
    }
}

.cdc{
    &-backoffice{
        &-menu{
            &-sublevel{
                padding-left: 32px !important;
            }
        }
        &-error{
            color: #d32f2f;
            font-family: "Roboto","Helvetica","Arial",sans-serif;
            font-weight: 500;
            line-height: 1.66;
            text-align: left;
            margin-top: 0;
            margin-bottom: 0;
            font-size: 14px;
        }
        &-file{
            border: 0;
            display: flex;
            width: 100%;
            height: 50px;
            border-radius: 10px;
            justify-content: flex-start;
            align-items: center;
            background-color: inherit;
            position: relative;
            input{
                cursor: pointer;
                line-height: 45px;
            }
            &-pdf{
                height: calc(100vh - 270px);
                width: calc(100vw - 200px);
                max-width: 552px;
            }
            &-delete{
                background-color: transparent;
                border: none;
                cursor: pointer;
            }
            &-label{
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                color: #1565c0;
                text-align: center;
                position: absolute;
                display: flex;
                align-items: center;
                margin-left: 0;
                cursor: pointer;
            }
            &-upload{
                opacity: 0;
                height: 100%;
                cursor: pointer;
            }
        }
        &-thread{
            &-download{
                text-decoration: none;
                color: #1565c0;
            }
            &-response{
                margin-bottom: 30px;
            }
            &-space{
                margin-bottom: 35px;
                border-bottom: 2px solid #f2f2f2;
            }
            &-buttons{
                padding-left: 24px;
                padding-right: 24px;
                background-color: #f5f5f5;
                display: flex;
                flex-direction: row;
                -webkit-box-pack: justify;
                justify-content: space-between;
                min-height: 64px;
                align-items: center;
            }
            &-textfield{
                input{
                    padding: 8.5px 14px;
                }
            }
            &-main{
                margin-left: 2%;
                margin-right: 10%;
                margin-top: 1em;
                display: flex;
            }
            &-container{
                background-color: #fff;
                color: rgba(0, 0, 0, 0.87);
                -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                border-radius: 4px;
                box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
                overflow: hidden;
                flex: 1 1 auto;
            }
            &-form{
                &-container{
                    padding: 16px;
                }
            }
        }
        &-msg{
            width: 100%;
            word-break: break-all;
            background-color: rgba(0, 0, 0, 0.04);
            border-bottom: 1px solid;
            border-top: 1px solid;
            cursor: pointer;
            &:hover{
                background-color: #fff;
            }
            td{
                padding: 1em 0;
            }
            &-summary{
                text-overflow: ellipsis; 
                overflow: hidden; 
                white-space: nowrap;
                max-width: 300px;
            }
            &-data{
                p{
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
            &-list{
                width: 100%;
                background-color: rgba(0, 0, 0, 0.04);
                padding: 1em;
                border-collapse: collapse;
            }
            &-content{
                padding: 1em;
                border-top: 1px solid;
                margin-top: 10px;
                background-color: #fff;
                max-height: 84px;
                cursor: pointer;
                &:last-child{
                    border-bottom: 1px solid;
                }
                &:hover{
                    background-color: rgba(0, 0, 0, 0.04);
                }
                &.first{
                    margin-top: 0;    
                }
                td{
                    vertical-align: middle;
                }
                &-sender{
                    font-weight: 700;
                    margin-bottom: 0;
                    margin-top: 0;
                    white-space: nowrap;
                }
                &-date{
                    margin: 0;
                    color: rgba(0, 0, 0, 0.38);
                    font-weight: 500;
                }
                &-text{

                }
            }
            &-line{
                height: 1px;
                width: 100%;
            }
        }
        &-input{
            &-multiline{
                .MuiInputBase-multiline{
                    padding: 20px;
                }
            }
        }
        &-button{
            &-delete{
                padding: 6px 16px !important;
                border-radius: 4px !important;
                color: white !important;
                background-color: #d32f2f !important;
                line-height: 1.75 !important;
            }
            &-download{
                padding: 6px 8px;
                border-radius: 4px;
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 1.75;
                letter-spacing: 0.02857em;
                text-transform: uppercase;
                display: inline-block;
                &:hover{
                    background-color: rgba(25, 118, 210, 0.04);
                }
            }
        }
        &-filters{
            &-search{
                margin-top: 0 !important;
            }
            form{
                padding: 0;
            }
        }
        &-newtab{
            background-color: transparent;
            border: 0;
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }
}

.cdc-v3-home-container {
    background-color: #EEF0E5;

    .cdc-v3-home-box-title {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        text-align: center;

        color: #fff;
        font-size: 22px;
        padding: 40px 8px;
    }

    .cdc-v3-bg-caza {
        background-image: url(../../assets/home/ctc-home-b5-bg.jpg);
    }

    .cdc-v3-bg-area {
        background-image: url(../../assets/home/ctc-about-b1-bg.png);
    }

    .cdc-v3-bg-socio {
        background-image: url(../../assets/serviciosPWA.svg);
    }

    .cdc-v3-bg-amigo {
        background-image: url(../../assets/home/ctc-friemd-b1-bg.jpg);
    }

    .cdc-v3-home-box-body {
        display: flex;
        gap: 10px;
        justify-content: center;
        padding-top: 10px;
        padding-bottom: 10px;
        flex-wrap: wrap;

        .cdc-v3-home-box-item {
            width: calc(50% - 15px);
            
            a {
                height: 60px;
                margin: 0 !important;
            }

            .cdc-v3-home-box-btn {
                background-color: #304D30;
                color: #fff;
                border-radius: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                //box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
                box-shadow: 1px 1px 3px 1px rgba(0,0,0,.15);
                text-shadow: 1px 1px 0px rgba(0,0,0,.15);
            }
        }
    }
}

.cdc-v3-home-box {
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    overflow: auto;
    margin-bottom: 25px;
    
    a {
        margin-bottom: 0 !important;
    }
}

.cdc-v4-block-head {
    background-color: #163020;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    padding: 10px 10px;
    margin-bottom: 20px;

    .cdc-v4-block-head-title {
        font-weight: 400;
        font-size: 22px;
        flex-grow: 1;
        text-align: center;
    }
    .cdc-v4-block-img {
        height: 40px;
    }
}

.cdc-v4-block-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 25px;

    .cdc-v4-action-item{
        width: calc(50% - 5px);

        .cdc-v4-block-btn-img {
            height: 25px;
        }
    }
    a {
        background-color: #304D30;
        border-radius: 8px;
        display: flex;
        gap: 6px;
        flex-direction: column;
        padding: 10px 0;
        margin: 0;
    }
    .cdc-v4-block-btn {
        color: #304D30;
        background-color: #FFFFFF;
        border-radius: 8px;
        padding: 6px;
        margin: 0 10px;
    }
}

.cdc-v2-home-box {
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    overflow: auto;
}

.cdc-v2-home-container {
    display: flex;
    flex-direction: row;
    //gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 25px;

    .cdc-v2-home-box-item  {
        background-color: #304D30;
        //width: calc(50% - 5px);
        width: 50%;

        a {
            margin-bottom: 0 !important;
            color: #FFFFFF !important;
            
            div {
                padding: 9px;
                font-size: 16px;
            }

            h1 {
                color: #FFFFFF !important;
                font-size: 16px;
                font-weight: 300;
            }
        }
    }

    .cdc-v2-home-box-item-100  {
        background-color: #FFFFFF;
        width: 100%;
    }

    .cdc-v2-home-box-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        

        figure.title-img {
            text-align: center;
            margin: 0;
            img {
                width: 100%;
            }
        }
        h1 {
            font-family: "Roboto", Sans-serif;
            font-size: 24px;
            font-weight: 600;
            line-height: 19px;
        }
    }
}





